import React, { Component, createRef, useEffect } from "react";
import ReactECharts from "echarts-for-react";
import isEqual from 'lodash.isequal';
import html2canvas from "html2canvas";
import { ChartsEvents } from "./ChartsEvent";
import Menu from '@mui/material/Menu';

import { TRACKHEIGHT, TRACKSDEFAULTWIDTH, VERTICALSCALEGAP } from "./ChartsDefaultValues";
import KogChartsHeader from "./KogChartsHeader";
import ContextMenuComponent from "./ContextMenuComponent";


function normalizeData(data:any[], max:number, min:number) {
    return data.map((value) => {
        if( value[0] ) {
            return [(value[0] - min) / (max - min), value[1]]
        }
        else{
            return value
        }
    });
}

function unnormalizeData(data:any[], max:number, min:number) {
    return data.map((value) => {
        if( value[0] ) {
            return [(value[0] - min) * (max - min),[value[1]]]
        }
        else{
            return value
        }
    });
}

/**
 * Depth Reference Interface.
 * @name
 */
interface DepthReference {
  name: string;
  values: any[];
}

interface MarkerLine{
    name: string;
    depth: number;
}

/**
 * Group Data Interface
 * @name data name.
 * @data plot's data using cartesian coordinates [[x,y],[x,y]].
 * @min X min value.
 * @max X max value.
 */
interface GroupData {
  name: string;
  data: any[];
  min?: number;
  max?: number;
  markerLine?: MarkerLine[];
}

/**
 * Tracks data Interface
 * @name group name.
 */
export interface TracksData {
  name: string;
  groupData: GroupData[];
  depthReference: DepthReference;
}

interface contextMenuState {
    xPos:number,
    yPos:number,
    show:boolean
}

/**
 * Tracks State.
 */
interface TracksState {
  plotData: any[];
  axisX: any[];
  axisY: any[];
  grid: any[];
  dataZoom: any[];
  groupsIdx:any;
  showTracks:boolean;
  ploting:boolean;
  width:number;
  idxBoundaries:any;
  prevData: any;
  prevConfig: any|undefined;
  canvasId:string;
  height:number;
  wellsIdxs:any;
  groupValues:any;
//   headerGroupDataset:any;
  displayMenu:boolean;
  menuAnchor:any;
  contextMenuOptions:contextMenuState;
  contextMenuResponse:any
  graphics:any[]
}

interface ColorData{
    line:string;
    fill:string;
}

interface PlotDisplay{
    colorsData?: {[index: string]:ColorData};
    tracksGap:number;
    tracksWidth:number;
    lineMarkerType?:string;
    tracksLineWidth?:number;
    tracksVerticalScale?:boolean;
    tracksShowZoom?:boolean;
    tracksHeight?:number;
    fontSize?:number;
}

/**
 * Tracks Props Interface
 * @data (required) plot data.
 * @plotConfig (optional) view configs.
 * @ploatDisplay (optional) layout display configs.
 * @trackLeftClickOptions (optional) Left menu click options.
 * @progressStatus optional Callback method to display tracks progress.
 * @defaultGroupsIdx Optional, prev state groupsIdx to display tracks and group tracks order.
 */
interface TracksProps {
  data: TracksData[];
  plotConfig?: PlotDisplay;
  plotDisplay?: any;
  trackLeftClickOptions?:any[];
  progressStatus?:any;
  defaultGroupsIdx?: {[index: string]:any};
  tracksIdxCallback?: any;
}

export default class Tracks extends Component<TracksProps, TracksState> {

    chartRef:any;
    chartContext:string;
    updateEventCreated:boolean
    headerGroupDataset:any

  constructor(props: any) {
    super(props);
    this.headerGroupDataset = {}
    this.chartRef = createRef();
    let conf = {}
    if(this.props.plotConfig){
        conf = this.props.plotConfig;
    }
    let heightPlot: any = TRACKHEIGHT + 60;
    if(this.props.plotConfig != undefined && this.props.plotConfig.tracksHeight != undefined){
        heightPlot = this.props.plotConfig.tracksHeight + 60;
    }
    this.updateEventCreated= false;
    this.state = {
        plotData: [],
        axisX: [],
        axisY: [],
        grid: [],
        dataZoom:[],
        groupsIdx: {},
        showTracks:true,
        ploting:true,
        width: 0,
        height:heightPlot,
        idxBoundaries:{},
        canvasId: "capture-" + Math.floor(Math.random() * 1000),
        prevData:JSON.parse(JSON.stringify(this.props.data)),
        prevConfig: JSON.parse(JSON.stringify(conf)),
        wellsIdxs: {},
        groupValues:{},
        // headerGroupDataset:{},
        displayMenu:false,
        menuAnchor:null,
        contextMenuOptions:{
            xPos:0,
            yPos:0,
            show:false
        },
        contextMenuResponse:{},
        graphics:[]
    }
    this.processTracksData(this.props.data);
    this.chartContext = `tracksChart-${Math.floor(Math.random()*100000)}`
  }

  mouseMove = (event:any) => {
    // console.log("entrou!")
    const chartInstance = this.chartRef.current.getEchartsInstance();

    var pointInPixel = [event.offsetX, event.offsetY];

    var series = chartInstance.getOption().series;

    let wellsIndexes = this.state.wellsIdxs;

    var values = series
      .filter(function (sereie:any) {
        return !sereie.areaStyle;
      })
      .map(function (sereie:any) {
        var data = sereie.data;
        var dataIndex = chartInstance.convertFromPixel(
          { seriesIndex: series.indexOf(sereie) },
          pointInPixel
        );

        var nearestIndex = sereie.data.findIndex((value:any) => value[1] >= dataIndex[1]);
        if (nearestIndex > 0) {
          var x1 = data[nearestIndex - 1][0];
          var y1 = data[nearestIndex - 1][1];
          var x2 = data[nearestIndex][0];
          var y2 = data[nearestIndex][1];

          var interpolatedY = x1 + ((dataIndex[1] - y1) / (y2 - y1)) * (x2 - x1);
          let wellName = wellsIndexes[sereie.xAxisIndex];
          return { name: sereie.name, value: interpolatedY, index: wellName  };
        }
      })
      .filter((value:any) => value && (value.index || value.value)); // Remove valores nulos
      
      let currentGroupsData:any = {}
      if(values.length > 0){
          values.forEach((data:any)=>{
            if(!Object.keys(currentGroupsData).includes(data.index)){
                currentGroupsData[data.index] = {}
            }
            currentGroupsData[data.index][data.name] = data.value
          })

      }
      this.setState({groupValues:currentGroupsData})

  };

  componentDidMount(): void {
    this.createChartsEvents()
    document.addEventListener(ChartsEvents.updateTrackConfigEvent, this.updateLayout as EventListener);
  }

    createChartsEvents(){
        if (this.chartRef.current) {
            const echartsInstance = this.chartRef.current.getEchartsInstance();
            const zr = echartsInstance.getZr();
            this.updateLines()
            // zr.on("mousemove", this.mouseMove);
            zr.on("contextmenu",this.createContextEvent);
            this.updateEventCreated=true;
            document.addEventListener('click', this.addClickevent);
            
            echartsInstance.on("dataZoom", this.updateLines);
            window.addEventListener("resize", this.updateLines);
        }
    }

    updateLines = () =>{
        if (this.chartRef.current) {
            const chart = this.chartRef.current.getEchartsInstance();
            let grid_series = []
            let series = this.state.plotData.filter((data,data_idx,data_arr)=>{
                if(data_idx==0){
                    return true
                }
                else{
                    return data.xAxisIndex != data_arr[data_idx-1].xAxisIndex 
                }
            });
            series.sort((a:any, b:any) => a.xAxisIndex - b.xAxisIndex);
            let grids = this.state.grid;
            grids.sort((a:any, b:any) => a.left - b.left);
            let graphics:any = [];
            // this.setState({showTracks:false})

            grids.forEach((grid:any,gridIdx:number, gridArray:any)=>{
                if( gridIdx <  gridArray.length - 1){
                    let markLineGridA: {[index: string]:number} = {}
                    let markLineGridB: {[index: string]:number} = {}
                    series[gridIdx].markLine.data.forEach((markerData:any,markerIdx:any)=>{
                        markLineGridA[markerData.name] = markerData.yAxis
                    })
                    series[gridIdx+1].markLine.data.forEach((markerData:any,markerIdx:any)=>{
                        markLineGridB[markerData.name] = markerData.yAxis
                    })
                    Object.keys(markLineGridA).forEach((markerName)=>{
                        if( Object.keys(markLineGridB).includes(markerName) ){
                            const grid0 = chart.convertToPixel({ xAxisIndex: series[gridIdx].xAxisIndex, yAxisIndex: series[gridIdx].yAxisIndex }, [0,markLineGridA[markerName]]);
                            const grid1 = chart.convertToPixel({ xAxisIndex: series[gridIdx+1].xAxisIndex, yAxisIndex: series[gridIdx+1].yAxisIndex }, [0,markLineGridB[markerName]]);
                            let lineWidth = 1;
                            if(this.props.plotConfig && this.props.plotConfig.tracksLineWidth){
                                lineWidth = this.props.plotConfig.tracksLineWidth;
                            }
                            graphics.push({
                                type: "line",
                                shape: {
                                        x1: grids[gridIdx].left + grids[gridIdx].width,
                                        y1: grid0[1],
                                        x2: grids[gridIdx+1].left + VERTICALSCALEGAP +10,
                                        y2: grid1[1],
                                    },
                                style: { stroke: "black", lineWidth: lineWidth, lineDash: "dashed", },
                                id: `line-${markerName}-${gridIdx}-${gridIdx+1}`,
                                z:500
                            })
                        }
                    })
                }
            })
            // this.setState({graphics:graphics})
            chart.setOption({
                graphic: graphics
            });            
        }
    }

addClickevent = (event:any)=>{
    this.setState({contextMenuOptions:{...this.state.contextMenuOptions, show:false}})
}

createContextEvent =(event:any)=> {
    window.addEventListener('contextmenu', function (e) {
        e.preventDefault();
    });
    this.leftClick(event);
}

  componentWillUnmount() {
    if (this.chartRef.current) {
        this.chartRef.current.getEchartsInstance().getZr().off("contextmenu",this.createContextEvent);
    }
    document.removeEventListener('click', this.addClickevent);
    document.removeEventListener(ChartsEvents.updateTrackConfigEvent, this.updateLayout as EventListener);

  }

  leftClick = (chartParameters:any) =>{
    // if(this.props.trackLeftClickOptions){
    //     const options = this.props.trackLeftClickOptions.map((option:)=>{
    //         console.log("option: ", option.displayName)
    //     })
    // }
    // const rect = this.chartRef.current.getBoundingClientRect();
    const chartInstance = this.chartRef.current.getEchartsInstance();

    var pointInPixel = [chartParameters.event.offsetX, chartParameters.event.offsetY];

    var series = chartInstance.getOption().series;
    let clickData: {[index: string]:any} = {}
    series.forEach((serie:any)=>{
        if(!Object.keys(clickData).includes(serie.groupName)){
            clickData[serie.groupName] = {}
        }
        const currentAxis = chartInstance.convertFromPixel({ seriesIndex: series.indexOf(serie) },pointInPixel)
        clickData[serie.groupName][serie.name] = {currentY:currentAxis[1]}
    })
    const currentTrack = this.getCurrentGridIndex(chartParameters);
    this.setState({
        contextMenuOptions:{
            xPos:chartParameters.event.clientX,
            yPos:chartParameters.event.clientY,
            show:true
        },
        contextMenuResponse:{
            currentGroupName:currentTrack,
            clickData:clickData
        }
    })
  }
  
  fixTrackData = (tracksData:any[], idxBoundaries:any ) =>{
    return tracksData.map((trackData, idx)=>{
        let currentData = trackData;
        currentData.data = normalizeData(currentData.data,idxBoundaries[currentData.xAxisIndex].max, idxBoundaries[currentData.xAxisIndex].min)
        return currentData;
    })
  }

  getCurrentGridIndex = (chartParameters:any) =>{



    // Pega a posição do clique do mouse
    const x = chartParameters.event.offsetX;
    const y = chartParameters.event.offsetY;

    const charts = this.chartRef.current.getEchartsInstance();
    const grids = charts.getOption().grid;
    // const zr = charts.getZr();
    const dom = charts.getDom()
    const boundingRect = dom.getBoundingClientRect()

    for (let i = 0; i < grids.length; i++) {
        const grid = grids[i];

        // Verifica se as dimensões do grid são números ou strings (percentual)
        const gridLeft = typeof grid.left === 'string' && grid.left.includes('%')
            ? (parseFloat(grid.left) / 100) * boundingRect.width
            : parseFloat(grid.left);
        
        const gridTop = typeof grid.top === 'string' && grid.top.includes('%')
            ? (parseFloat(grid.top) / 100) * boundingRect.height
            : parseFloat(grid.top);
        
        const gridWidth = typeof grid.width === 'string' && grid.width.includes('%')
            ? (parseFloat(grid.width) / 100) * boundingRect.width
            : parseFloat(grid.width);
        
        const gridHeight = typeof grid.height === 'string' && grid.height.includes('%')
            ? (parseFloat(grid.height) / 100) * boundingRect.height
            : parseFloat(grid.height);

        console.log(`Grid ${i}: X=${gridLeft}, Y=${gridTop}, Width=${gridWidth}, Height=${gridHeight}`);
        console.log(`Clique: X=${x}, Y=${y}`);

        if (
            x >= gridLeft &&
            x <= gridLeft + gridWidth &&
            y >= gridTop &&
            y <= gridTop + gridHeight
        ) {
            console.log('Clique de contexto realizado no grid de índice: ' + i);
            // Lógica adicional aqui para manipular o grid correspondente
            return charts.getOption().series[i].groupName
        }
    }
  }

  componentDidUpdate(prevProps: any, previousState:any){
    
    let update = false;
    this.props.data.forEach((data:any, idx:number)=>{
        let result = isEqual(data, this.state.prevData[idx]);
        if(!result){
            update = true;
        }
    })
    const newObject = this.props.data.map((data)=>{return data.name});
    const prevObject = this.state.prevData.map((data:any)=>{return data.name});
    if( JSON.stringify(newObject) != JSON.stringify(prevObject) ){
        update = true;
    }
    if(!isEqual(this.props.plotConfig, prevProps.plotConfig) && this.props.plotConfig != undefined){
        update = true;
    }
    if(update){
        const new_data = JSON.parse(JSON.stringify(this.props.data));
        let plotConfig = {}
        if(this.props.plotConfig){
            plotConfig = JSON.parse(JSON.stringify(this.props.plotConfig));
        }
        let heightPlot: any = TRACKHEIGHT + 60;
        if(this.props.plotConfig != undefined && this.props.plotConfig.tracksHeight != undefined){
            heightPlot = this.props.plotConfig.tracksHeight + 60;
        }
        if (this.chartRef.current) {
            this.chartRef.current.getEchartsInstance().getZr().off("contextmenu",this.createContextEvent);
        }
        this.headerGroupDataset={}
        this.setState({prevData:new_data, ploting:true, groupsIdx:{}, axisX:[], axisY:[], grid:[], dataZoom:[], plotData:[], prevConfig:plotConfig, width:0, height:heightPlot},()=>{
            this.processTracksData(new_data);
            
        })
    }
  }


    createColor = () => {
        // The available hex options
        let hex = ['a', 'b', 'c', 'd', 'e', 'f', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

        /**
         * Randomly shuffle an array
         * https://stackoverflow.com/a/2450976/1293256
         */
        let shuffle = (hex:any) => {

            let currentIndex = hex.length;
            let temporaryValue, randomIndex;

            // While there remain elements to shuffle...
            while (0 !== currentIndex) {
                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex -= 1;

                // And swap it with the current element.
                temporaryValue = hex[currentIndex];
                hex[currentIndex] = hex[randomIndex];
                hex[randomIndex] = temporaryValue;
            }

        };

        /**
         * Create a six-digit hex color
         */
        let hexColor = () => {
            // Create the color
            let color = '#';

            // Shuffle the hex values and append
            for (let i = 0; i < 6; i++) {
                shuffle(hex);
                color += hex[0];
            }

            return color;
        };

        // Return the color string
        return hexColor();
    }

  processTracksData = (data:TracksData[]) =>{
    // if( this.props.progressStatus ){
    //     this.props.progressStatus(70, "Ploting data");
    // }
    let plotData:any[] = this.state.plotData;
    let plotIdx = 0;
    let groupsIdx: {[index: string]:any} = this.state.groupsIdx;
    // TODO: Review this block above, can be better!
    if(this.props.defaultGroupsIdx && Object.keys(this.props.defaultGroupsIdx).length > 0 && data.length > 0){
        let groups = this.props.defaultGroupsIdx;

        data.forEach((group:TracksData)=>{
            const groupData = group.groupData.map((groupData)=>{return groupData.name});
            let data:number[] = []
            if(groups[group.name] != undefined){
                data = Object.keys(groups[group.name]).map((propertyName)=>{return groups[group.name][propertyName]})
            }
            let maxIdx = 0;
            if(data.length > 0){
                maxIdx = Math.max.apply(NaN,data);
            }
            // Clean unecesary data
            let allProperties:string[] = groupData
            if(groups[group.name] != undefined){
                allProperties = [... new Set(groupData.concat(Object.keys(groups[group.name])))]
            }
            allProperties.forEach((dataName)=>{
                if(groups[group.name] == undefined){
                    groups[group.name] = {}
                }
                if(groupData.includes(dataName) && groups[group.name][dataName] == undefined){
                    maxIdx ++;
                    groups[group.name][dataName] = maxIdx;
                }
                if(!groupData.includes(dataName)){
                    delete groups[group.name][dataName]
                }
            })
            // Reindex data
            let oldIdx:any[] = []
            if(groups[group.name] != undefined){
                oldIdx = [... new Set( Object.keys(groups[group.name]).map((propertyName)=>{return groups[group.name][propertyName]}))]
            }
            oldIdx.forEach((currentIdx)=>{
                const prevItems = Object.keys(groups[group.name]).filter(propertyName=>groups[group.name][propertyName] == parseInt(currentIdx))
                prevItems.forEach(propertyName =>{
                    groups[group.name][propertyName] = plotIdx;
                })
                plotIdx ++
            })
        })
        let groupsInDataset = data.map((groupData)=>{return groupData.name})
        Object.keys(groups).forEach(group=>{
            if(!groupsInDataset.includes(group)){
                delete groups[group];
            }
        })
        groupsIdx = groups;
    }
    let idxBoundaries:any = {}
    let headerGroupDataset = this.headerGroupDataset;
    data.forEach((group,groupIdx)=>{
        if( !Object.keys(groupsIdx).includes(group.name) ){
            groupsIdx[group.name] = {}
        }
        let plotConfig:any = {};
        if(this.props.plotConfig){
            plotConfig = this.props.plotConfig;
        }
        group.groupData.forEach((groupData, groupDataIdx)=>{
            let colorsConf:{[index: string]:ColorData} = {}
            if(plotConfig.colorsData){
                colorsConf = plotConfig.colorsData;
            }
            
            if( !Object.keys(groupsIdx[group.name]).includes(groupData.name) ){
                groupsIdx[group.name][groupData.name] = plotIdx;
            }
            let markData: any[] = [];
            if( groupData.markerLine ){
                markData = groupData.markerLine.map( (mark, markIdx )=>{
                    let lineColor = "#000000";
                    if(colorsConf[mark.name]){
                        lineColor = colorsConf[mark.name].line;
                    }
                    return {
                        yAxis: mark.depth, // Profundidade específica para marcar
                        name:mark.name,
                        label: {
                          formatter: groupDataIdx==0?mark.name:"",
                          position:"middle",
                          fontWeight:"bold",
                          fontSize:9,
                          padding:[0,0,-6,0]
                        //   formatter: "",
                        },
                        lineStyle: {
                            width: plotConfig.tracksLineWidth,
                            color: lineColor,
                            type: "dashed",
                        },
                      }
                } )
            }
            if(!Object.keys(headerGroupDataset).includes(group.name)){
                headerGroupDataset[group.name] = []
            }
            headerGroupDataset[group.name].push(groupData.name)
            let currentPlotData:any = {
                name: groupData.name,
                type: "line",
                showSymbol: false,
                groupName:group.name,
                symbol:'none',
                data:groupData.data,
                emphasis: { focus: 'none' },
                yAxisIndex: groupsIdx[group.name][groupData.name],
                xAxisIndex: groupsIdx[group.name][groupData.name],
                markLine:{
                    symbol:"none",
                    data: markData
                }
            };
            let lineStyle:any = {
                //color:"#7F0037"
                color: this.createColor()
            }
            if(colorsConf[groupData.name]){
                lineStyle.color = colorsConf[groupData.name].line;
            }
            if(plotConfig.tracksLineWidth){
                lineStyle.width = plotConfig.tracksLineWidth;
            }
            if(Object.keys(lineStyle).length > 0){
                currentPlotData.lineStyle = lineStyle;
            }
            plotData.push(currentPlotData);
            plotIdx ++;
        });
    });
    idxBoundaries = this.processIdxBoundaries(plotData);
    this.setState({plotData:plotData, groupsIdx:groupsIdx, idxBoundaries:idxBoundaries},()=>{
        this.processTracksLayout(groupsIdx);
        // this.updateLines();
    });
  }

  updateLayout = (event:any) =>{
    // if (event.type !== ChartsEvents.updateTrackConfigEvent && event.detail.context == this.chartContext){
    //     return;
    // }
    const newView = event.detail.data;
    if(this.props.tracksIdxCallback){
        if(!isEqual(this.props.defaultGroupsIdx,newView)){
            this.props.tracksIdxCallback(newView)
        }
    }
    this.setState({ploting:false,axisX:[], axisY:[], grid:[], dataZoom:[],width:0, showTracks:false},()=>{
        this.updateTracksIndex(newView, ()=>{
            this.processTracksLayout(newView);
        });
    })
  }

  updateTracksIndex = (groupsIdx:any, callback?:any) =>{
    let plotData = this.state.plotData.map((data)=>{
        let current_data = data;
        current_data.yAxisIndex = groupsIdx[data.groupName][data.name]
        current_data.xAxisIndex = groupsIdx[data.groupName][data.name]
        return data
    })
    const newIdxBoundaries = this.processIdxBoundaries(plotData);
    this.setState({plotData:plotData, idxBoundaries:newIdxBoundaries}, ()=>{
        if(callback){
            callback();
        }
    });
  }

  /**
   * Process IdxBoundaries.
   * @param plotData Plots data.
   * @returns return idxBoundaries.
   */
  processIdxBoundaries = (plotData:any) => {
    let idxBoundaries: {[index: number]:any} = {}
    plotData.forEach((currentData:any,dataIdx:number)=>{
        let idx_min = 0;
        let idx_max = 0;
        const dataArr = currentData.data.map((data:any)=>{return data[0]}).filter((value:any)=> value!=null)

        if(!Object.keys(idxBoundaries).includes(currentData.yAxisIndex.toString())){
            idx_min = Math.min.apply(NaN,dataArr);
            idx_max = Math.max.apply(NaN,dataArr);
        }
        else{
            idx_min = idxBoundaries[currentData.yAxisIndex].min;
            idx_max = idxBoundaries[currentData.yAxisIndex].max;
        }

        const current_idx_min = Math.min.apply(NaN,dataArr);
        if(idx_min > current_idx_min){
            idx_min = current_idx_min;
        }
        const current_idx_max = Math.max.apply(NaN,dataArr);
        if(idx_max < current_idx_max){
            idx_max = current_idx_max;
        }
        
        idxBoundaries[currentData.yAxisIndex] = {min:idx_min, max:idx_max}
    })
    return idxBoundaries;
  }

  processTracksGroupsPositions = (groupsIdx:{[index: string]:any}):string[] => {
    let data:any[] = []
    Object.keys(groupsIdx).forEach((groupName)=>{
        let currentData:number[] = [];
        Object.keys(groupsIdx[groupName]).forEach((propertyName)=>{
            currentData.push(groupsIdx[groupName][propertyName]);
        })
        data.push([groupName, Math.min(...currentData)]);
    })
    if(data.length > 0){
        data.sort((a, b) => a[1] - b[1]);
        return data.map((currentData)=>{return currentData[0]});
    }
    return []
  }

  processTracksLayout (groupsIdx:{[index: string]:any}){
    const groupsAsc:string[] = this.processTracksGroupsPositions(groupsIdx)
    let trackIdx = 0;
    let dataZoom = this.state.dataZoom;
    let grid:any[] = this.state.grid;
    let axisX:any[] = this.state.axisX;
    let axisY:any[] = this.state.axisY;
    let xPos = this.state.width;
    let trackWidth = TRACKSDEFAULTWIDTH;
    let trackGap = VERTICALSCALEGAP;
    let wellsIdxs = this.state.wellsIdxs;
    if(this.props.plotConfig){
        trackWidth = this.props.plotConfig.tracksWidth;
    }
    if(this.props.plotConfig){
        trackGap = this.props.plotConfig.tracksGap + VERTICALSCALEGAP;
    }
    let groupIndexes = 0;
    if( groupsAsc.length > 0 && this.state.plotData.length > 0 ){
        let preProcessedLabel:number[] = [];
        let groupNames:any[] = []
        /**Pre processing */
        let plotData = this.state.plotData;
        plotData = this.fixTrackData(JSON.parse(JSON.stringify(plotData)),this.state.idxBoundaries);
        const idxBoundaries = this.processIdxBoundaries(plotData)
        groupsAsc.forEach((groupName)=>{
            let currentLabels = Object.keys(groupsIdx[groupName]).map((trackName)=>{
                return groupsIdx[groupName][trackName];
            });
            preProcessedLabel = Array.from(new Set(currentLabels));
            groupNames = preProcessedLabel.map((idx)=>groupName);
            if( this.props.plotConfig?.tracksShowZoom ){
                dataZoom.push({
                    left: xPos,
                    type: "slider",
                    start: 0,
                    end: 100,
                    filterMode: "filter",
                    disabled: false,
                    yAxisIndex: preProcessedLabel
                });
                trackIdx ++;
                xPos = xPos + 80;
            }
            
            
            preProcessedLabel.forEach((labelIdx:any, idx:any)=>{
                grid.push({
                    left: xPos,
                    top: 10,
                    width: trackWidth,
                    containLabel: true,
                    height:this.state.height,
                    // backgroundColor: "#000",
                    borderColor: "#000",
                });
                axisX.push({
                    type: "value",
                    // name: "Valor",
                    min: idxBoundaries[labelIdx].min,
                    max: idxBoundaries[labelIdx].max,
                    gridIndex: labelIdx,
                    display: true,
                    axisLabel:{
                        show:false,
                        fontSize: this.props.plotConfig?.fontSize?this.props.plotConfig?.fontSize:10,
                        // symbol:"none",
                        // formatter: function (value:number, index:number) {
                        //   return value.toFixed(2);
                        // }
                    }
                });
                const groupName = groupNames.shift();
                const currentY:any[] = this.getGroupIdx(groupName).depthReference.values;
                axisY.push({
                    type: "value",
                    data: currentY,
                    name: "MD (m)",
                    min: Math.min.apply(NaN,currentY),
                    max: Math.max.apply(NaN, currentY),
                    show: idx==0?true:this.props.plotConfig?.tracksVerticalScale,
                    inverse: true, // Inverte o eixo y para que a profundidade aumente para baixo
                    gridIndex: labelIdx,
                    axisLabel:{
                        formatter: function (value:number, index:number) {
                          return value.toFixed(2);
                        },
                    fontSize: this.props.plotConfig?.fontSize?this.props.plotConfig?.fontSize:10,
                    }
                })                
                xPos = xPos + trackWidth + trackGap;
                trackIdx ++;
                wellsIdxs[groupIndexes] = groupName;
                groupIndexes++;
            });
        });
        this.setState({plotData:plotData, dataZoom, grid:grid, axisX:axisX, axisY:axisY,  width:xPos, wellsIdxs:wellsIdxs},()=>{
            this.setState({ploting:false, showTracks:true},()=>{
                this.createChartsEvents();
                if(this.props.progressStatus){
                    this.props.progressStatus(100.0, `Done`);
                }
                if(this.props.tracksIdxCallback){
                    if(!isEqual(this.props.defaultGroupsIdx,groupsIdx)){
                        this.props.tracksIdxCallback(groupsIdx)
                    }
                }
                setTimeout(()=>{
                    this.updateLines();
                },2000)
            })
        });
    }
  }

  getGroupIdx = (groupName:string) =>{
    return this.state.prevData.filter( (currentData:any)=> currentData.name == groupName )[0]
  }

  printPlot = () =>{
    const item:any = document.getElementById(this.state.canvasId);
    html2canvas(item).then((canvas) => {
        var link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "plot.png";
        link.click();
      });
  }

  getPlotsWidth = () =>{
    // let width = 0;
    // let trackWidth = TRACKSDEFAULTWIDTH;
    // let trackGap = VERTICALSCALEGAP;
    // if(this.props.plotConfig){
    //     trackWidth = this.props.plotConfig.tracksWidth;
    //     trackGap = this.props.plotConfig.tracksGap;
    // }
    // width = ((trackWidth + trackGap) * (this.state.axisX.length + 2)) + (trackGap * this.props.data.length);
    
    // if(this.props.plotConfig?.tracksShowZoom){
    //     width = width + ((trackWidth + 80) * this.props.data.length)
    // }
    const last_item = this.state.grid[this.state.grid.length-1]
    return last_item.left + last_item.width + 50;
  }

  render(): React.ReactNode {
    if(!this.state.ploting && this.props.data.length > 0){
        let options = {
            tooltip:{
                trigger: "axis",
                axisPointer: {
                    type: "cross",
                },
                showContent:false
                // show:false
            },
            legend:{show:false},
            toolbox:{show:false},
            dataZoom:this.state.dataZoom,
            grid:this.state.grid,
            xAxis:this.state.axisX,
            yAxis:this.state.axisY,
            series:this.state.plotData,
            graphic:this.state.graphics,
            textStyle:{
                fontSize: this.props.plotConfig?.fontSize?this.props.plotConfig?.fontSize:10,
                // fontWeight:'bold',
                // color: '#bbf'
              }
        }
        return (
            <>
                <div style={{display: 'inline-flex', justifyContent: 'flex-end'}}>                    
                    <input type="button" className="btn export-btn" onClick={()=>{this.printPlot()}} value="export" />
                    <ContextMenuComponent 
                        options={this.props.trackLeftClickOptions?this.props.trackLeftClickOptions:[]}
                        xPos={this.state.contextMenuOptions.xPos}
                        yPos={this.state.contextMenuOptions.yPos}
                        show={this.state.contextMenuOptions.show}
                        width={"20vh"}
                        callbackResponse={this.state.contextMenuResponse}
                    />
                </div>

                <Menu id="basic-menu" open={this.state.displayMenu} />
                {/* <div style={{overflowX:"auto", maxHeight:`${this.state.height + 70}px`}}> */}
                <div>
                    <div id={this.state.canvasId} style={{
                        // width: this.getPlotsWidth(), 
                        }}>
                        {/* <div style={{width: this.getPlotsWidth(), display: "flex"}}>
                            <div style={{width: this.getPlotsWidth(), display: "flex"}}>
                                {Object.keys(this.state.groupValues).map((groupName:any)=>{
                                    return Object.keys(this.state.groupValues[groupName]).map((propertyName)=>{
                                        return <div style={{width:200, paddingLeft:(50), paddingRight:(50)}}>{groupName} - {propertyName} - {this.state.groupValues[groupName][propertyName].toFixed(2)} </div>
                                    })
                                })}
                            </div>

                        </div> */}


                        <div className="KogChartsHeaderContainer">
                            <KogChartsHeader initialData={this.state.groupsIdx} plotConfig={this.props.plotConfig} context={this} tracksData={this.props.data}/>
                        </div>
                        { this.state.showTracks && (
                            <ReactECharts
                            option={options}
                            style={{ 
                                minHeight: this.state.height + 20, 
                                width: this.getPlotsWidth() 
                            }}
                            ref={this.chartRef}
                        />
                        ) }
                        
                    </div>
                </div>
            </>
          )
    }
    else{
        return(<></>)
    }
    }
}
