import React, { Component } from 'react';
import { StandartColors } from '../../../utils/enums/StandartColors';
import ToastHelper from '../../../utils/helpers/ToastHelper';
import { EventNames } from '../../../utils/enums/EventNames';
import FixWellInfosTable, {updateTable} from '../../FixWellInfosTable/FixWellInfosTable';
import LoadFileComponent from '../../LoadFileComponent/LoadFileComponent';
import SelectDataComponent from '../../SelectDataComponent/SelectDataComponent';
import {OneSpace, DoubleQuotation, Hash, Meter, MD, m9999_99} from '../../ParserFields/ParserValues';
import AttributeParameters, {well_name, surface_x, surface_y, MD as MD_header, uwi, KB, RT, GL, TD, lat, long} from '../../PreviewTable/TableOptions';
import { upload } from '../../../services/wellService';
import { refreshDataTab } from '../DataTabComponent/DataTabComponent';


interface WellHeadsComponentState {
  fileList: any[],  
  activeTab: string,  
  dataList: any[],
  newWellsNames: any,
  fileData: any[],
  headersColumns: any,
  delimiter: string,
  qualifier: string,
  comment: string,
  depthUnit: string,
  depthType: string,
  noValue: string,
  startLine: number,
}

const headerOptions : {[index: string]:any} = { uwi, KB, RT, GL, TD, lat, long }

enum TabNames{
  uploadFile = "Load File",
  selectData = "Select Data",
  correctWellInfo = "Correct Well Info"
}

const tabOrder: string[] =[
  TabNames.uploadFile,
  TabNames.selectData,
  TabNames.correctWellInfo,
]

const CONTEXT = 'wellHeads';

class WellHeadsComponent extends Component<{}, WellHeadsComponentState> {  
  updatedParserEvent: string = EventNames.updatedParserEvent;
  changeWellsNamesEvent = EventNames.updatedWellNamesEvent;
  previewTableChangedEvent: string = EventNames.previewTableChangedEvent;
  loadFileComponentEvent: string = EventNames.LoadFileComponentEvent;
  SelectDataComponentEvent: string = EventNames.SelectDataComponentEvent;  
  SelectDataComponentClickUploadButton: string = EventNames.SelectDataComponentClickUploadButton;  
  SelectDataComponentParserInfoEvent: string = EventNames.SelectDataComponentParserInfoEvent;  

  constructor(props: any) {
    super(props);
    this.state = {
      activeTab: TabNames.uploadFile,
      fileList: [],      
      dataList:[],
      newWellsNames:{},
      fileData:[],
      startLine: 1,
      delimiter:OneSpace.value,
      qualifier:DoubleQuotation.value,
      comment:Hash.value,
      depthUnit:Meter.value,
      depthType:MD.value,
      noValue:m9999_99.value,
      headersColumns:{
        well:1,
        xColumn:4,
        yColumn:5,
        mdColumn:9,
      },      
    };
  }

  componentDidMount() {
    document.addEventListener(this.loadFileComponentEvent, this.setFiles);
    document.addEventListener(this.SelectDataComponentEvent, this.receiveDataset);
    document.addEventListener(this.SelectDataComponentClickUploadButton, this.receiveClickUploadButton);
    document.addEventListener(this.SelectDataComponentParserInfoEvent, this.receiveParserInfo);
  }

  componentWillUnmount() {    
    document.removeEventListener(this.loadFileComponentEvent, this.setFiles);
    document.removeEventListener(this.SelectDataComponentEvent, this.receiveDataset);
    document.removeEventListener(this.SelectDataComponentClickUploadButton, this.receiveClickUploadButton);
    document.removeEventListener(this.SelectDataComponentParserInfoEvent, this.receiveParserInfo);
  }

  setFiles = (event:any) => {
    if (event.type !== this.loadFileComponentEvent && event.detail.context != CONTEXT){
      return;
    }

    const dataset = event.detail;
    this.setState({fileList:dataset.fileList},()=>{});
  }

  receiveDataset = (event:any) => {
    if (event.type !== this.SelectDataComponentEvent && event.detail.context != CONTEXT){
      return;
    }

    const dataset = event.detail;
    this.setState({dataList: dataset.dataList})
    updateTable(dataset.dataList, CONTEXT);
  }

  receiveClickUploadButton = (event:any) => {
    if (event.type !== this.SelectDataComponentEvent && event.detail.context != CONTEXT){
      return;
    }

    const dataset = event.detail;
    this.upload();
  }

  receiveParserInfo = (event:any) => {
    if (event.type !== this.SelectDataComponentParserInfoEvent && event.detail.context != CONTEXT){
      return;
    }

    const dataset = event.detail;
    this.setState({dataList: dataset.dataList, newWellsNames: dataset.newWellsNames, fileData: dataset.fileData, startLine: dataset.startLine})
    updateTable(dataset.dataList, CONTEXT);    
  }

  createDefaultColumns = () =>{
    let defaultColumns:any = {};   

    defaultColumns[this.state.headersColumns.well] = well_name.value
    defaultColumns[this.state.headersColumns.xColumn] = surface_x.value
    defaultColumns[this.state.headersColumns.yColumn] = surface_y.value
    defaultColumns[this.state.headersColumns.mdColumn] = MD_header.value
    
    return defaultColumns
  }  

  /**
   * Upload data
   */
  upload = () =>{

    if(this.state.fileList.length == 0){
      ToastHelper.warning("There is no information to load, please add files in 'Load File' tab and try again.");
      return;
    }

    let new_well_names:any = {};

    this.state.dataList.forEach((well)=>{
      new_well_names[well[1]] = well[2];
    });

    

    // Object.keys(this.state.newWellsNames).forEach((filename:string,idx:number)=>{
    //   Object.keys(this.state.newWellsNames[filename]).forEach((old_wellName:string,wellIdx)=>{
    //     new_well_names[old_wellName] = this.state.newWellsNames[filename][old_wellName]
    //   });
    // });

    let dataset: any[] = []

    let filteredFileData = this.state.fileData.filter((line, idx)=>{
      if(idx + 1 >= this.state.startLine){
        return line  
      }      
    })
    
    dataset = filteredFileData.map((row:any)=>{
      let optionals: any[] = [];
      Object.keys(this.state.headersColumns).map((column:string)=>{
        if(Object.keys(headerOptions).includes(column)){
          optionals.push({
            mnemonic: headerOptions[column].value,
            description:headerOptions[column].display,
            unit: "",
            value: row[this.state.headersColumns[column]]                            
          })
        }
      })
      
      return {
        name: new_well_names[row[this.state.headersColumns.well]],
        x: row[this.state.headersColumns.xColumn],
        y: row[this.state.headersColumns.yColumn],
        md: row[this.state.headersColumns.mdColumn],
        depth_reference: this.state.depthType,
        distance_unit: this.state.depthUnit,
        optionals: optionals
      }      
    });
    dataset = dataset.filter((well_header)=> well_header.name)
    if(dataset.length == 0){
      ToastHelper.warning("Could not parse file(s) correctly, please check parameters in 'Select Data' tab and try again.");
      return;
    }
   
    upload(dataset).then((response)=>{
      if(response.data.error || response.status != 200) {
        ToastHelper.error("Error while Uploading well headers, please check file and try again.", ()=>{});
      }else {
        ToastHelper.success("Upload well headers was successful! ");
      }
      refreshDataTab();
    });
  }
  
  setTab = (tabName:string) => {
    this.setState({activeTab:tabName});
  }

  tabHeader = () =>{
    return (
      <div className="process-nav">{tabOrder.map((currentTab,idx)=>{
        return (
          <div className={`col k-tab ${currentTab==this.state.activeTab?"activeTab":""}`} onClick={()=>{this.setTab(currentTab)}}>
            {currentTab}
          </div>
        )
      })}
      </div>
    )
  }

  showCorrectWellInfoTabContent = () =>{
    if(this.state.dataList.length > 0){
      return (
        <>
          <div className='associateLabel'>
            <h3>Correct information read in file:</h3>                        
          </div>
          <div className='table-container styled-scrollbars'>
            <FixWellInfosTable wellsData={this.state.dataList} context={CONTEXT} />
          </div>
        </>
      )
    }
    return <div className='correctWellInfoAlert'>Please load a Well Header file.</div>
  }

  tabsNavButton = () =>{
    let activeTabIdx = tabOrder.indexOf(this.state.activeTab);
    return (
        <div className="tabsNavButton">
            { tabOrder.indexOf(this.state.activeTab) != 0 && <button className='nav-btn' onClick={()=>{this.setTab(tabOrder[activeTabIdx - 1])}}>
                back
            </button>}
            { tabOrder.indexOf(this.state.activeTab) < tabOrder.length - 1 && <button className='nav-btn btn-next' onClick={()=>{this.setTab(tabOrder[activeTabIdx + 1])}}>
                next
            </button>}
            { tabOrder.indexOf(this.state.activeTab) ==  tabOrder.length -1 && this.state.dataList.length > 0 && <button className='nav-btn btn-next' onClick={()=>{this.upload()}}>
                upload
            </button>}
        </div>
    )
  }

  tabsView = () =>{

    return (
      <section>
        <div>          
          {this.tabHeader()}
        </div>        
        <div className="tab-content borderedDiv">
          <div
            className={`tab-pane fade show ${this.state.activeTab === TabNames.uploadFile ? 'active' : ''}`}              
            role="tabpanel"
            style={{textAlign:"center"}}               
          >
            <LoadFileComponent context={CONTEXT}/>
          </div>
          <div
            className={`tab-pane fade show ${this.state.activeTab === TabNames.selectData ? 'active' : ''}`}              
            role="tabpanel"                         
          >
            <SelectDataComponent context={CONTEXT} 
              headersColumns={this.state.headersColumns} 
              defaultColumns={this.createDefaultColumns()} 
              delimiter={this.state.delimiter} 
              qualifier={this.state.qualifier} 
              comment={this.state.comment}
              depthUnit={this.state.depthUnit}
              depthType={this.state.depthType}
              noValue={this.state.noValue}
              listHeaderOptions={new AttributeParameters().well_header}
            />
          </div>
          <div
            className={`tab-pane fade show ${this.state.activeTab === TabNames.correctWellInfo ? 'active' : ''}`}              
            role="tabpanel"                
          >
            
            {this.showCorrectWellInfoTabContent()}
            
          </div>
        </div>
      </section>
    )
  }

  render(){
    return(
      <>
        <h1>Import Well Heads</h1>
        {this.tabsView()}
        {this.tabsNavButton()}
      </>
    )
  }

}

export default WellHeadsComponent;
