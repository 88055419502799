import { DepthsReferenceList } from "./DepthsReferences";
import { TracksData } from "./Tracks";

/**
 * Depth Reference Interface.
 * @name
 */
interface DepthsReference {
    name: string;
    values: any[];
}

interface MarkerLine{
    name: string;
    depths: {[index: string]:number};
}

/**
 * Group Data Interface
 * @name data name.
 * @data plot's data using cartesian coordinates [[x,y],[x,y]].
 * @min X min value.
 * @max X max value.
 */
interface GroupData {
    name: string;
    data: any[];
    min?: number;
    max?: number;
    // markerLine?: MarkerLine[];
  }

/**
 * Tracks data Interface
 * @name group name.
 */
export interface TracksDataStructure {
    name: string;
    groupData: GroupData[];
    depthReference:DepthsReference;
}

export default class DataManagement{
    groupsData: TracksDataStructure[];
    groupsNames: string[];

    constructor(){
        this.groupsData = [];
        this.groupsNames = [];
    }

    updateGroupsData = (data:TracksDataStructure[]) =>{
        data.forEach((groupData:TracksDataStructure)=>{
            if(this.groupsNames.includes(groupData.name)){
                this.updateGroupData(groupData);
            }
            else{
                this.addGroupData(groupData);
            }
        })
    }

    updateGroupData = (data:TracksDataStructure) => {
        let currentGroup = this.groupsData.find((currentData)=>currentData.name == data.name);
        if(currentGroup){
            data.groupData.forEach((current_data)=>{
                let prev_data = currentGroup?.groupData.find((current_group_data)=>current_group_data.name == current_data.name)
                if(prev_data){
                    prev_data.data = current_data.data;
                    prev_data.min = current_data.min;
                    prev_data.max = current_data.max;
                }
                else{
                    currentGroup?.groupData.push(current_data);
                }
            })
        }
    }

    addGroupData = (data:any) => {
        this.groupsNames.push(data.name);
        this.groupsData.push(data)
    }

    updateWellMarkers = (markersData:any)=>{

    }

    getPlotData = (request_data:any) =>{
        let plot_data: any[] = [];
        request_data.forEach((currentGroup:any)=>{
            let currentGroupData = {}
            const group = this.groupsData.find((group)=>group.name == currentGroup.wellname);
            if(group){
                const logs = group.groupData.filter((data)=> currentGroup.logs.includes(data.name))
                currentGroupData = {
                    name:group.name,
                    depthReference:group.depthReference,
                    groupData: logs
                }
            }
            plot_data.push(currentGroupData);
        })

        return plot_data;
    }

    checkMissingData = (request_data:any[]) =>{
        let new_request:any[] = [];
        request_data.forEach((currentGroup)=>{
            const group = this.groupsData.find((group)=>group.name == currentGroup.wellname);
            let depth_reference = group?.depthReference.name?group?.depthReference.name:"MD"
            if(!group && depth_reference == currentGroup.depth_reference){
                new_request.push(currentGroup)
            }
            else{
                let logs:any[] = [];
                let newGroup = {
                    version: currentGroup.version,
                    wellname: currentGroup.wellname,
                    depth_reference: currentGroup.depth_reference,
                    markers_list: currentGroup.markers_list,
                    logs:logs
                };
                currentGroup.logs.forEach((data_name:string)=>{
                    
                    let current_data = group?.groupData.find((data)=>data.name == data_name);
                    if(!current_data){
                        logs.push(data_name);
                    }
                })
                if(logs.length>0){
                    newGroup.logs = logs;
                    new_request.push(newGroup);
                }
            }
        })
        return new_request;
    }

}