export enum EventNames {
    resizeEvent = 'resizeEvent',        
    CsvUploaderComponentUpdateListEvent = 'CsvUploaderComponentUpdateListEvent',
    changeTabEvent = 'changeTabEvent', 
    changeTabEvent2 = 'changeTabEvent2',    
    removeAllFilesEvent = 'eventRemoveAllFiles',    
    checkedTreeViewEvent = 'checkedTreeViewEvent',
    refreshWellNodesEvent = 'refreshWellNodesEvent',
    refreshTreeComponent = 'refreshTreeComponent',
    uploadMarkerEvent = 'uploadMarkerEvent',
    uploadLasEvent = 'uploadLasEvent',
    changeWellLasName = 'changeWellLasName',
    hideNodesEvent = 'hideNodesEvent',
    refreshWellTrackPlotEvent = 'refreshWellTrackPlotEvent',
    wellColorsConfigEvent = 'wellColorsConfigEvent',
    selectFileEvent = 'selectFileEvent',
    addFileListEvent = 'addFileListEvent',
    previewTableChangedEvent = 'previewTableChangedEvent',
    updatedParserEvent = 'updatedParserEvent',
    updatedWellNamesEvent = 'updatedWellNamesEvent',
    changeCorrelationViewDataEvent = 'changeCorrelationViewDataEvent',
    refreshCorrelationTreeViewEvent = 'refreshCorrelationTreeViewEvent',
    refreshWellsTreeViewEvent = 'refreshWellsTreeViewEvent',
    refreshMarkersTreeViewEvent = "refreshMarkersTreeViewEvent",
    LoadFileComponentEvent = "LoadFileComponentEvent",
    SelectDataComponentEvent = "SelectDataComponentEvent",
    SelectDataComponentClickUploadButton = "SelectDataComponentClickUploadButton",
    SelectDataComponentParserInfoEvent = "SelectDataComponentParserInfoEvent",
    addSelectedFilesEvent = "addSelectedFilesEvent",
    SelectItemsListEvent = "SelectItemsListEvent",
    refreshSelectItemsList = "refreshSelectItemsList"
  }