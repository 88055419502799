/* eslint-disable */

import { RestApiService } from "./RestApiService";
import { getUserCurrentProject, getToken, getProjectToken } from '../utils/helpers/TokenHelper';
import { Host_Well, Host_Correlation } from "../Config/Host";

import '../utils/helpers/AxiosInterceptor';

export async function upload(params) {
  return new Promise((resolve, reject) => {
    // resolve({message: 'Success!' });
    var token = getProjectToken();
    var endpoint = `${Host_Well}/well/header/`;
    RestApiService.post2(endpoint, params, token)
    .then(response => {      
      resolve(response);
    })
    .catch(error => {
      reject(error);
    });
  });
}

export async function updateWellHeader(params) {
  return new Promise((resolve, reject) => {
    // resolve({message: 'Success!' });
    var token = getProjectToken();
    var endpoint = `${Host_Well}/well/header/`;
    RestApiService.put(endpoint, params, token)
    .then(response => {      
      resolve(response);
    })
    .catch(error => {
      reject(error);
    });
  });
}

export async function exists(params) {
  return new Promise((resolve, reject) => {
    resolve({repeatedList: params.wellNameList[0]});
    /*var endpoint = `${Host_Well}/well/exists`;
    RestApiService.post(endpoint, params)
    .then(response => {      
      resolve({response, wellHeaderList});
    })
    .catch(error => {
      reject(error);
    });*/  
  });
}

export async function uploadWellLogs(params){
  var endpoint = `${Host_Well}/well/log_object`;
    return new Promise((resolve, reject) => {
    var token = getProjectToken();
    RestApiService.upload(endpoint, params, token)
    .then(response => {      
      resolve({response});
    })
    .catch(error => {
      reject(error);
    });
  });
}

export async function uploadWellDeviation(params){
  let endpoint = `${Host_Well}/well/load_deviation`;
    return new Promise((resolve, reject) => {
    let token = getProjectToken();
    RestApiService.upload(endpoint, params, token)
    .then(response => {      
      resolve({response});
    })
    .catch(error => {
      reject(error);
    });
  });
}

export async function wellMetadata(){

  var endpoint = `${Host_Well}/well/`;
  var token = getProjectToken();
  return new Promise((resolve, reject) => {
    RestApiService.get(endpoint, token)
    .then(response => {      
      resolve(response);
    })
    .catch(error => {
      reject(error);
    });
  });
}

export async function wellBoundaries(){

  var endpoint = `${Host_Well}/well/boundaries/`;
  var token = getProjectToken();
  return new Promise((resolve, reject) => {
    RestApiService.get(endpoint, token)
    .then(response => {      
      resolve(response);
    })
    .catch(error => {
      reject(error);
    });
  });
}

export async function getWellDeviationProperties(){

  var endpoint = `${Host_Well}/well/deviation/properties`;
  var token = getProjectToken();
  return new Promise((resolve, reject) => {
    RestApiService.get(endpoint, token)
    .then(response => {      
      resolve(response);
    })
    .catch(error => {
      reject(error);
    });
  });
}

// export async function getSeaLevel(){

//   var endpoint = `${Host_Well}/well/info/sea_level_conversion/`;
//   var token = getProjectToken();
//   return new Promise((resolve, reject) => {
//     RestApiService.get(endpoint, token)
//     .then(response => {      
//       resolve(response);
//     })
//     .catch(error => {
//       reject(error);
//     });
//   });
// }

export async function getCorrelations(params){
  return new Promise((resolve, reject) => {
    var token = getProjectToken();
    var endpoint = `${Host_Correlation}/correlations/total`;
    RestApiService.post(endpoint, params, token)
    .then(response => {      
      resolve(response);
    })
    .catch(error => {
      reject(error);
    });
  });
}  


export async function deleteLog(log, callbackSuccess, callbackError) {
  var request_data = {}
  request_data["wellname"] = log.well.label;
  request_data["version"] = Number(log.log.version_id);
  request_data["logs"] = [log.log.label];
  var token = getProjectToken();
  RestApiService.delete(Host_Well + "/well/logs/", request_data, token)
    .then((response) => {
      if (response.status === 200) {
        callbackSuccess();
      }else{
        console.log(response)
        callbackError();
      }
    })
    .catch((e) => {
      callbackError();
    });
}

export async function deleteVersion(version, callbackSuccess, callbackError) {
  var request_data = {}
  request_data["wellname"] = version[0].well.label;
  request_data["version"] = Number(version[1].version.id);
  var token = getProjectToken();
  RestApiService.delete(Host_Well + "/well/version/", request_data, token)
    .then((response) => {
      if (response.status === 200) {
        callbackSuccess();
      }else{
        console.log(response)
        callbackError();
      }
    })
    .catch((e) => {
      callbackError();
    });
}

export async function deleteWells(data, callbackSuccess, callbackError) {
  var request_data = {}
  var token = getProjectToken();
  request_data["wellname"] = data.well.label;
  RestApiService.delete(Host_Well + "/well/", request_data, token)
    .then((response) => {
      if (response.status && response.status === 200) {
        callbackSuccess();
      }else{
        console.log(response)
        callbackError();
      }
    })
    .catch((e) => {
      callbackError();
    });
}

export async function getLogs(params) {
  return new Promise((resolve, reject) => {
    var token = getProjectToken();
    var endpoint = `${Host_Well}/well/logs/generic`;
    RestApiService.post(endpoint, params, token)
    .then(response => {      
      resolve(response);
    })
    .catch(error => {
      reject(error);
    });
  });
}

export async function getTracksLogs(params) {
  return new Promise((resolve, reject) => {
    var token = getProjectToken();
    var endpoint = `${Host_Well}/well/logs/track`;
    RestApiService.post(endpoint, params, token)
    .then(response => {      
      resolve(response);
    })
    .catch(error => {
      reject(error);
    });
  });
}

export async function getDlisMetadata(){

  var endpoint = `${Host_Well}/well/metadata/dlis`;
  var token = getProjectToken();
  return new Promise((resolve, reject) => {
    RestApiService.get(endpoint, token)
    .then(response => {      
      resolve(response);
    })
    .catch(error => {
      reject(error);
    });
  });
}

export async function loadDlisFiles(params) {
  return new Promise((resolve, reject) => {
    var token = getProjectToken();
    var endpoint = `${Host_Well}/well/load/dlis`;
    RestApiService.post(endpoint, params, token)
    .then(response => {      
      resolve(response);
    })
    .catch(error => {
      reject(error);
    });
  });
}

export async function getWellHostInfo() {
  return new Promise((resolve, reject) => {
    var token = getProjectToken();
    var endpoint = `${Host_Well}/well/host_infos`;
    RestApiService.get(endpoint, token)
    .then(response => {      
      resolve(response);
    })
    .catch(error => {
      reject(error);
    });
  });
}