//External packages
import React, { Component } from 'react';
import Switch from '@mui/material/Switch';
import isEqual from 'lodash.isequal';

// Internal Packages
import { StandartColors } from '../../../../../utils/enums/StandartColors';
import { wellMetadata } from '../../../../../services/wellService';
import { CorrelationTabEvents } from '../../../../../utils/enums/CorrelationsTab';
import { getUserConfs, saveUserConfs } from '../../../../../services/projectService';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

interface PropertiesSet{
    name:String,
    propertiesNames: String[]
}

interface PropertiesCorrelationTabState {
    properties: String[],
    propertiesSelected: String[],
    wellsByProperties: any,
    propertiesSets:PropertiesSet[],
    currentPropertySet: number,
    selectedAll:boolean,
    newPropertySetName:string,
    timerToSend:any,
    prevState:any,
    metadata:any,
}

interface PropertiesCorrelationTabProps {
    wellsSelected:string[]
}

class PropertiesCorrelationsTab extends Component<PropertiesCorrelationTabProps, PropertiesCorrelationTabState> {

    propertyUpdateEvent: string = CorrelationTabEvents.propertyUpdate;
    previousTabEvent: string = CorrelationTabEvents.previousTabEvent;
    nextTabEvent: string = CorrelationTabEvents.nextTabEvent; 

    constructor(props:any){
        super(props);
        this.state = {
            properties: [],
            propertiesSelected: [],
            wellsByProperties: {},
            propertiesSets:[{name:"All Properties", propertiesNames: []}],
            currentPropertySet:0,
            selectedAll:false,
            newPropertySetName:"",
            timerToSend:null,
            prevState:JSON.parse(JSON.stringify(this.props)),
            metadata:{}
        }
        
        
    }

    updateWells = () =>{
        const well_metadata = wellMetadata()
        let logNames:any[] = []
        let propertiesWells: any = {}
        well_metadata.then((metadata)=>{
            Object.keys(metadata).forEach((wellName:any, idx:any) => {
                if(metadata[wellName].curves){
                    metadata[wellName].curves.versions.forEach((version:any,version_idx:any)=>{
                        Object.keys(version.curves_info).forEach((logName:any,logIdx:any)=>{
                            if(!logNames.includes(logName) && logName != 'MD'){
                                logNames.push(logName);
                            }
                        });
                    });
                }
            });  

            propertiesWells = this.getNumberWellsByProperty(metadata);

                            
            this.setState({properties:logNames, metadata:metadata},()=>{
                this.setState({wellsByProperties:propertiesWells});
                this.selectAll(true);
                this.setTimerToSendChanges();
                this.loadPropertiesSets();
            });
        });
    }

    componentDidMount(): void {
        this.updateWells();
    }

    componentDidUpdate(prevProps: Readonly<PropertiesCorrelationTabProps>, prevState: Readonly<PropertiesCorrelationTabState>, snapshot?: any): void {
        let update = false;
        if(!isEqual(this.props, this.state.prevState)){
            update = true;
        }
        if(update){
            this.setState({prevState:JSON.parse(JSON.stringify(this.props))},()=>{
                let propertiesWells = this.getNumberWellsByProperty(this.state.metadata);
                this.setState({wellsByProperties:propertiesWells});
            })
        }

    }

    /**
     * Save property set.
     */
    savePropertySet = () =>{
        if(this.state.newPropertySetName !== ""){
            saveUserConfs([{"topic":"sets", "group":"property", "key": `${this.state.newPropertySetName}`, "value":this.state.propertiesSelected.toString()}]).then((response)=>{
                this.loadPropertiesSets()
            });
        }
        else{
            alert("Well set name field cannot set empty");
        }
    }

    /**
     * Load Properties sets from User's config
     */
    loadPropertiesSets = () =>{
        const projectConf = getUserConfs();
        const allWells = JSON.parse(JSON.stringify(this.state.properties))
        projectConf.then((response)=>{
            if(response.sets){
                const sets = response.sets;
                var propertiesSets:PropertiesSet[] = [{name:"All Properties", propertiesNames: allWells}];
                if(sets.property){
                    Object.keys(sets.property).forEach((propertySet,setIdx)=>{
                        propertiesSets.push({ name:propertySet, propertiesNames:sets.property[propertySet].split(",") })
                    });
                }
                this.setState({propertiesSets:propertiesSets},()=>{
                    // this.changeWellSet(this.state.currentWellSet)
                });
            }
        });
    }

    /**
     * 
     * @param propertySet 
     */
    changePropertySet = (propertySet:number) =>{
        let selectedAll = this.state.propertiesSets[propertySet].propertiesNames.length == this.state.properties.length;
        const propertiesNames = JSON.parse(JSON.stringify(this.state.propertiesSets[propertySet].propertiesNames))
        this.setState({currentPropertySet:propertySet, propertiesSelected:propertiesNames, selectedAll:selectedAll},()=>{
            this.sendConfs()
        });
    }

    checkOption =(event:any, check_name:string) =>{
        let checked = event.target.checked;
        let properties = this.state.propertiesSelected;
        if(checked){
            properties.push(check_name);
        }
        else{
            properties.splice(properties.indexOf(check_name),1);
        }
        let selectedAll = this.state.propertiesSelected.length == this.state.properties.length;
        this.setState({propertiesSelected:properties, selectedAll:selectedAll});
        this.setTimerToSendChanges()
    }

    selectAll = (selected:boolean) =>{
        if(selected){
            let well_names = JSON.parse(JSON.stringify(this.state.properties));
            this.setState({propertiesSelected:well_names, selectedAll:true});
        }
        else{
            this.setState({propertiesSelected:[], selectedAll:false});
        }
        this.setTimerToSendChanges();
    }

    setTimerToSendChanges = () => {
        if(this.state.timerToSend != null){
            clearTimeout(this.state.timerToSend);
          }
          var timer = setTimeout(this.sendConfs,100);
          this.setState({timerToSend:timer});
    }

    sendConfs = () =>{
        const customEvent = new CustomEvent(this.propertyUpdateEvent, { detail: {
            properties:this.state.propertiesSelected,
            currentPropertySet: this.state.currentPropertySet
        } 
        });
        document.dispatchEvent(customEvent);
    }

    showPropertiesNames = () =>{
        

             
        return(
            <>                  
                <TableContainer>
                    <Table>
                        <TableHead className='backgroundColorBlack'>
                            <TableRow>
                                <TableCell>
                                    <b>Select a property/s from {this.state.propertiesSets[this.state.currentPropertySet].name}:</b>
                                    <div>
                                        <div className="onoffswitch">                  
                                            <input type="checkbox" 
                                                name="selectAllProperty" 
                                                className="onoffswitch-checkbox" 
                                                id="selectAllProperty" 
                                                checked={this.state.selectedAll} 
                                                onChange={(event)=>{this.selectAll(event.target.checked)}}
                                                />
                                            <label className="onoffswitch-label" htmlFor="selectAllProperty"></label>
                                        </div>
                                        <label>                                    
                                            Select/Deselect all
                                        </label>
                                    </div>                                
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                this.state.properties.map((prop_name:any)=>{
                                    let numberWellsbyProperty = 0
                                    Object.entries(this.state.wellsByProperties).forEach((key:any)=>{
                                        if(prop_name == key[0]){
                                            numberWellsbyProperty = key[1].length
                                        }
                                    })
                                    return <>
                                        <TableRow>
                                            <TableCell>
                                                <label className='correlationLabel'>
                                                    <input type='checkbox' onChange={(event)=>{this.checkOption(event, prop_name);}}							
                                                        checked={this.state.propertiesSelected.includes(prop_name)}
                                                        defaultChecked={true}							
                                                    />                                                
                                                    {prop_name}({numberWellsbyProperty > 1 ? numberWellsbyProperty + " wells" : numberWellsbyProperty + " well"})
                                                </label>
                                            </TableCell>
                                        </TableRow>
                                    </>
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>                              
            </>
        )
    }

    changeNewPropertySetName = (event:any) => {
        this.setState({newPropertySetName:event.target.value});
    }

    getNumberWellsByProperty = (metadata:any) =>{
        let wellsByProperty: any = {}

        Object.keys(metadata).forEach((wellName:any, idx:any) => {
            if(metadata[wellName].curves){
                Object.keys(metadata[wellName].curves.versions[0].curves_info).forEach((property:any,logIdx:any)=>{
                    if (!wellsByProperty[property]){
                        wellsByProperty[property] = []
                    }
                    if(this.props.wellsSelected.includes(wellName)){
                        wellsByProperty[property].push(wellName)
                    }
                });
            }
        });

        return wellsByProperty
    }

    render(){
        return(
            <>
                <div className='selectToCorrelate'>                    
                    <label>
                        Select the wells to correlate:
                        <span>
                            From:&nbsp; 
                            <select id="propSet" onChange={(event)=>{this.changePropertySet(parseInt(event.target.value))}}>
                                { this.state.propertiesSets.map( (propertySet,setIdx)=>{
                                    return( <option value={setIdx} defaultChecked={setIdx==0}>{propertySet.name}</option> )
                                } ) }
                            </select>

                        </span>
                    </label>                    
                </div>            
            
                <div className='table-container styled-scrollbars'>
                    {this.showPropertiesNames()}
                </div>

                <div className='saveTemplate'>
                    <div className='field'>
                        <label> Save as a template (optional):</label>
                        <input onChange={(event:any) => {this.changeNewPropertySetName(event)}} value={this.state.newPropertySetName} placeholder='template name'/>
                    </div>
                    
                    <input type="button" value="Save" className='save-button' onClick={()=>{this.savePropertySet()}} />
                </div>                  
            </>
        )
    }
}
export default PropertiesCorrelationsTab;