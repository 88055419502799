//Basic imports
import React, { Component } from 'react';
// External imports
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import DialogContent from '@mui/material/DialogContent';
import { Button } from '@mui/material';
// Our imports
import SelectItemsListComponent from '../../SelectItemsList/SelectItemsList';
import { getCorrelations, wellMetadata } from '../../../services/wellService';
// import { downloadMarkers, getMarkersData } from '../../../services/stratigraphicService';
// import ParserParameters, { DoubleQuotation, Hash, m9999_99, MD, Meter, OneSpace } from '../../ParserFields/ParserValues';
// import ParserFieldsComponent from '../../ParserFields/ParserFields';
// import FixWellInfosTable from '../../FixWellInfosTable/FixWellInfosTable';
import { EventNames } from '../../../utils/enums/EventNames';
import { deleteMultiwells, deletePairwise, getMultiwells } from '../../../services/correlationService';
import { refreshDataTab } from '../DataTabComponent/DataTabComponent';
import ToastHelper from '../../../utils/helpers/ToastHelper';

interface CorrelationsRemoveComponentState {    
    activeTab:string;
    wellsMetadata: any[];
    correlationsMetadata: any;
    correlationsNames: string[];
    showRemoveDialog:boolean;
    // wellsProperties: string[];
    // wellsMarkers: string[];
    // delimiter: string,
    // qualifier: string,
    // comment: string,
    // depthUnit: string,
    // depthType: string,
    // noValue: string,
    selectedCorrelations: string[],
    // selectedMarkers: string[],
}

// const CONTEXT = 'wellmarkers';

enum TabNames{
    selectWells = "Select Correlations",
    // selectMarkers = "Select Markers",
    // selectFormat = "Remove Correlations"
}

const tabOrder: string[] =[
    TabNames.selectWells,
    // TabNames.selectMarkers,
    // TabNames.selectFormat,
]

export default class CorrelationsRemoveComponent extends Component<{}, CorrelationsRemoveComponentState> {
    SelectItemsListEvent: string = EventNames.SelectItemsListEvent;  

    constructor(props: any) {
        super(props);        
        this.state = {
            activeTab: TabNames.selectWells,
            wellsMetadata: [],
            correlationsMetadata:{},
            correlationsNames: [],
            showRemoveDialog:false,
            selectedCorrelations: [],
        };
        // this.loadWellsData()
        this.loadCorrelationsData();
    }

    componentDidMount(){
        document.addEventListener(this.SelectItemsListEvent, this.receiveSelectedItems);
    }

    componentWillUnmount(){
        document.removeEventListener(this.SelectItemsListEvent, this.receiveSelectedItems);
    }

    loadCorrelationsData =()=>{
        getMultiwells().then(response=>{
            console.log("Help Leon!")
            this.setState({correlationsNames:Object.keys(response), correlationsMetadata:response})
        })
    }

    receiveSelectedItems = (event:any) => {
        if (event.type !== this.SelectItemsListEvent && (event.detail.context != "Wells" || event.detail.context != "Markers")){
          return;
        }
    
        const dataset = event.detail;
        
        if(event.detail.context == "Correlations"){
            this.setState({selectedCorrelations: dataset.items}, ()=>{}) 
        }            
    }

    setTab = (tabName:string) => {
        this.setState({activeTab:tabName});
    }

    tabHeader = () =>{
        return (
            <div className="process-nav">
                {tabOrder.map((currentTab,idx)=>{
                    return (
                        <div className={`col k-tab ${currentTab==this.state.activeTab?"activeTab":""}`} onClick={()=>{this.setTab(currentTab)}}>
                            {currentTab}
                        </div>
                    )
                })}
            </div>
        )
    }

    remove =() =>{
        const data = this.state.correlationsMetadata;
        let multiwellIdsList:number[] = [];
        let pairwiseIdsList:number[] = [];
        this.state.selectedCorrelations.forEach((name)=>{
            multiwellIdsList.push(data[name].id);
            pairwiseIdsList.push(data[name].pairwise_id);
        })

        deleteMultiwells({multiwells_ids:multiwellIdsList}).then(response=>{
            deletePairwise({pairwise_ids:pairwiseIdsList}).then(response=>{
                const responseData = response.data;
                if(response.status == 200){
                    if(responseData.errors.length == 0){
                        ToastHelper.success("All selected correlation was removed.");
                    }
                    if(responseData.errors.length > 0 && responseData.errors.length < this.state.selectedCorrelations.length){
                        ToastHelper.warning(`Some correlations could not be removed: ${responseData.errors.join(', ')}`);
                    }
                    if(responseData.errors.length == this.state.selectedCorrelations.length){
                        ToastHelper.error("Error in correlation remove." , ()=>{});
                    }
                }
                else{
                    ToastHelper.error("Error in correlation remove." , ()=>{});
                }
                this.loadCorrelationsData();
                refreshDataTab();
                this.setState({selectedCorrelations:[]})
            }).catch((error)=>{
                ToastHelper.error("Error in correlation remove." , ()=>{
                    console.log(error)
                });
            })
        })
    }

    tabsNavButton = () =>{
        let activeTabIdx = tabOrder.indexOf(this.state.activeTab);
        return (
            <div className="tabsNavButton">
                { tabOrder.indexOf(this.state.activeTab) != 0 && <button className='nav-btn' onClick={()=>{this.setTab(tabOrder[activeTabIdx - 1])}}>
                    back
                </button>}
                { tabOrder.indexOf(this.state.activeTab) < tabOrder.length - 1 && <button className='nav-btn btn-next' onClick={()=>{this.setTab(tabOrder[activeTabIdx + 1])}}>
                    next
                </button>}

                { tabOrder.indexOf(this.state.activeTab) == tabOrder.length - 1 && <button className='nav-btn btn-next' 
                    onClick={()=>{
                        if(this.state.selectedCorrelations.length > 0){
                            this.setState({showRemoveDialog:true})
                        }
                        else{
                            ToastHelper.error("Please, select an available correlation." , ()=>{});
                        }
                    }}>
                    Remove
                </button>}
            </div>
        )
    }

    removeDialog = () =>{
        return (
            <Dialog
            open={this.state.showRemoveDialog}
            onClose={()=>{ this.setState({showRemoveDialog:false}) }}
            PaperProps={{
              component:"form",
              onSubmit: (event: React.FormEvent<HTMLFormElement>) =>{
                event.preventDefault();
                this.setState({showRemoveDialog:false}, ()=>{
                  this.remove()
                })
              }
            }}
          >
            <DialogTitle>Are you sure you want to remove these items?</DialogTitle>
            <DialogContent>
                { this.state.selectedCorrelations.map((correlationName)=>{
                    return (<DialogContentText>{correlationName}</DialogContentText>)
                }) }
            </DialogContent>
            <DialogActions>
              {/* <Button onClick={()=>{this.setState({showRemoveDialog:false})}}>No, cancel</Button> */}
              <Button type='submit'>Remove</Button>
            </DialogActions>
          </Dialog>
        )
    }
  
    tabsView = () =>{
        return (
          <section id="tabs">
            {/* <div>          
              {this.tabHeader()}
            </div>  */}
            <div className="tab-content">
                <div
                    className={`tab-pane fade show ${this.state.activeTab === TabNames.selectWells ? 'active' : ''}`}          
                    role="tabpanel"
                    style={{textAlign:"center"}}    
                >
                    <SelectItemsListComponent listItems={this.state.correlationsNames} title='Correlations' context='Correlations'/>
                </div>
            </div>
            {this.tabsNavButton()}
          </section>
        )
    }
      
    
    render(): React.ReactNode {
        return (
            <>
                <h1>Remove Correlations Data</h1>
                {this.tabsView()}
                {this.removeDialog()}
            </>
        )
    }
}