import React, { Component } from "react";
import './ContextMenuComponent.css'

interface ConetxtMenuState{}

interface ContextMenuOptionsParameters{
    callback:any;
    displayName:string;
    blocked:boolean;
    where?:string;
}

interface ContextMenuProps{
    options:ContextMenuOptionsParameters[];
    show:boolean;
    xPos:number;
    yPos:number;
    width:number|string;
    callbackResponse:any;
}

export default class ContextMenuComponent extends Component<ContextMenuProps, ConetxtMenuState> {


    render(): React.ReactNode {
        if(this.props.show && this.props.options.length > 0){
            return(
                <div className="m-1" style={{
                    top:`${this.props.yPos}px`,
                    left:`${this.props.xPos}px`,
                    position:"absolute",
                    border:"1px solid #ccc",
                    width:this.props.width,
                    background:"white",
                    zIndex:800,
                    boxShadow: "5px 5px",
                }}>
                    {
                        this.props.options.map((option:ContextMenuOptionsParameters,optionIdx)=>{
                            let borderTop = "None";
                            if(optionIdx > 0){
                                borderTop = "1px solid #444";
                            }
                            let blocked = option.blocked?"contextMenuOptionBlocked":""
                            return (
                                <div className={`row contextMenuOption ${blocked}`} style={{cursor:"pointer", borderTop:borderTop}}>
                                    <div className="col p-1" onClick={
                                        (event)=>{
                                            if(!option.blocked){
                                                option.callback(this.props.callbackResponse)
                                            }
                                        }
                                    }>
                                        {option.displayName}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            )
        }
        else{
            return(<></>)
        }
    }
}