import React, { Component } from 'react';
import './TabComponent.css';
import { StandartColors } from "../../utils/enums/StandartColors";
import SettingsTabComponent from './SettingsTabComponent/SettingsTabComponent';
import ActionsTabComponent from './ActionsTabComponent/ActionsTabComponent';
import DataTabComponent from './DataTabComponent/DataTabComponent';
import { DataTypeTabNames } from '../../utils/enums/DataTypeTabNames';
import { EventNames } from '../../utils/enums/EventNames';

interface ConfigTabsComponentState {
  activeTab: DataTypeTabNames.DATA | DataTypeTabNames.ACTIONS | DataTypeTabNames.SETTINGS;
  viewTabName: string;
  extraViewTab: string;
}


class ConfigTabsComponent extends Component<{}, ConfigTabsComponentState> {

  changeTabEventName:string = EventNames.changeTabEvent;

  constructor(props: any) {
    super(props);
    this.state = {
      activeTab: DataTypeTabNames.ACTIONS, // Set the default active tab
      viewTabName: "",
      extraViewTab: ""
    };
  }

  handleTabClick = (tabId: any) => {
    this.setState({ activeTab: tabId });
  };

  componentDidMount() {  
    document.addEventListener(this.changeTabEventName, this.handleTabChangeEventListner);
  }

  componentWillUnmount() {
    document.removeEventListener(this.changeTabEventName, this.handleTabChangeEventListner);
  }

  handleTabChangeEventListner = (event: any) => {
    if (event.type !== this.changeTabEventName){
      return;
    }      

    var detail = event.detail;   
    var tabName = detail.tabName;
    var extraInfo = detail.extraInfo;
    this.setState({viewTabName:tabName,extraViewTab:extraInfo})
  };

  render() {
    const { activeTab } = this.state;



    return (
      <section id="tabs">
        <nav>
          <div className="nav nav-tabs nav-fill" id="nav-tab-data" role="tablist">
            <a
              className={`nav-item nav-link ${activeTab === DataTypeTabNames.ACTIONS ? 'active' : ''}`}
              id="actions-tab"
              onClick={() => this.handleTabClick(DataTypeTabNames.ACTIONS)}
              aria-controls="Actions"
              aria-selected={activeTab === DataTypeTabNames.ACTIONS}
            >
              Actions
            </a>
            <a
              className={`nav-item nav-link ${activeTab === DataTypeTabNames.DATA ? 'active' : ''}`}                  
              id="data-tab"
              onClick={() => this.handleTabClick(DataTypeTabNames.DATA)}
              aria-controls="Data"
              aria-selected={activeTab === DataTypeTabNames.DATA}
            >
              Data
            </a>
            
            <a
              className={`nav-item nav-link ${activeTab === DataTypeTabNames.SETTINGS ? 'active' : ''}`}
              id="settings-tab"
              onClick={() => this.handleTabClick(DataTypeTabNames.SETTINGS)}
              aria-controls="Settings"
              aria-selected={activeTab === DataTypeTabNames.SETTINGS}
            >
              Settings
            </a>                
          </div>
        </nav>
        <div className="tab-content overflow-auto styled-scrollbars sidebar-tab-content" id="nav-tab-data-component" style={{flexGrow: "1", overflowX:'auto'}}>          
          <div
            className={`tab-pane fade ${activeTab === DataTypeTabNames.ACTIONS ? 'show active' : ''}`}
            id="Actions"
            role="tabpanel"            
          >
              <ActionsTabComponent />
          </div>
          <div
            className={`tab-pane fade show ${activeTab === DataTypeTabNames.DATA ? 'active' : ''}`}
            id="Data"
            role="tabpanel"            
          >
            <DataTabComponent />
          </div>
          <div
            className={`tab-pane fade ${activeTab === DataTypeTabNames.SETTINGS ? 'show active' : ''}`}
            id="Settings"
            role="tabpanel"            
          >
            <SettingsTabComponent activeConfigTab={this.state.activeTab} viewName={this.state.extraViewTab} />
          </div>             
        </div>       
      </section>
    );
  }
}

export default ConfigTabsComponent;
