import React, { Component } from 'react';
import FileUploaderComponent from '../FileUploader/FileUploaderComponent';
import { EventNames } from '../../utils/enums/EventNames';
import ListFilesComponent, {addFiles} from '../ListFiles/ListFilesComponent';

interface LoadFileComponentProps{
  context: string;
}


class LoadFileComponent extends Component<LoadFileComponentProps> {
  addFilesEvent: string = EventNames.CsvUploaderComponentUpdateListEvent;  
  LoadFileEvent: string = EventNames.LoadFileComponentEvent;    
  selectFileEvent: string = EventNames.selectFileEvent;  
  

  constructor(props: any) {
    super(props);
  }

  componentDidMount() {  
    document.addEventListener(this.addFilesEvent, this.receiveFiles);    
    document.addEventListener(this.selectFileEvent, this.receiveSelectedFiles);
  }

  componentWillUnmount() {
    document.removeEventListener(this.addFilesEvent, this.receiveFiles);    
    document.removeEventListener(this.selectFileEvent, this.receiveSelectedFiles);
  }

  sendFiles = (fileList:any) => {
    const customEvent = new CustomEvent(this.LoadFileEvent, { detail: {
      fileList:fileList,
      context: this.props.context      
    }});
    document.dispatchEvent(customEvent);
  }

  receiveFiles = (event:any) =>{
    if (event.type !== this.addFilesEvent && event.detail.context != this.props.context){
      return;
    }
    let dataset = event.detail;
    addFiles(dataset.files, this.props.context);
    //this.sendFiles(dataset.files);
  }

  receiveSelectedFiles = (event: any) => {
    if (event.type !== this.selectFileEvent && event.detail.context != this.props.context){
      return;
    }
    let dataset = event.detail;    
    this.sendFiles(dataset.selectedFiles);    
  }
  
  render(){
    return (
      <>        
        <FileUploaderComponent fileList={[]} context={this.props.context}/>
        <ListFilesComponent fileList={[]} context={this.props.context}/>
      </>
    )
  }
}

export default LoadFileComponent;
