import React, { Component } from 'react';
import { EventNames } from '../../utils/enums/EventNames';
import './ListFilesComponent.css'

interface ListFilesProps{
    fileList: any[];
    context:string
}

interface ListFilesState{
    fileList: any[];
    selectedFiles: any[];
    isAllChecked: boolean;
}

/**
 * Dispatch add files event.
 * @param filesList List of files.
 * @param context Element's context.
 */
export function addFiles(filesList:number, context:string){
    const customEvent = new CustomEvent(EventNames.addFileListEvent, { detail: {
        filesList:filesList,
        context:context        
    } 
    });
    document.dispatchEvent(customEvent);
}

export default class ListFilesComponent extends Component<ListFilesProps, ListFilesState>{
    selectFileEvent: string = EventNames.selectFileEvent;
    addFileEvent: string = EventNames.addFileListEvent;
   
    constructor(props: any) {        
        super(props);
        this.state = {        
            fileList: this.props.fileList,
            selectedFiles: [],
            isAllChecked: true
        };
    }

    componentDidMount() {  
        document.addEventListener(this.addFileEvent, this.receiveFiles);        
    }
    
    componentWillUnmount() {
        document.removeEventListener(this.addFileEvent, this.receiveFiles);
    }

    populateSelectedFiles = () => {
        let selectedFiles:any[] = []
        this.state.fileList.map((file)=>{
            selectedFiles.push(file)
        })
        this.setState({selectedFiles: selectedFiles}, ()=> { this.sendSelectedFiles();})        
    }

    checkboxChange = (file: string) => {
        let selectedFiles = this.state.selectedFiles
        if (!selectedFiles?.includes(file)) {
            selectedFiles.push(file);
        } else{            
            selectedFiles.splice(selectedFiles.indexOf(file), 1)
        }
                
        this.setState({selectedFiles:selectedFiles}, ()=>{
            this.sendSelectedFiles();
            this.verifyAllFilesChecked();
        });        
    }

    verifyAllFilesChecked = () => {
        this.setState({isAllChecked: this.state.fileList.length == this.state.selectedFiles.length})        
    }

    sendSelectedFiles = () =>{        
        const customEvent = new CustomEvent(this.selectFileEvent, { detail: {
            selectedFiles:this.state.selectedFiles, 
            context:this.props.context
        } 
        });
        document.dispatchEvent(customEvent);        
    }

    /**
     * Add file list in component
     * @param event Event.
     */
    receiveFiles = (event:any) => {        
        if (event.type !== this.addFileEvent && event.detail.context != this.props.context){
            return;
        }
        let dataset = event.detail;
        this.setState({fileList:dataset.filesList}, ()=>{this.populateSelectedFiles();});
    }   

    handlerSelectAllClick = (isChecked: boolean) => {
        let files: any[] = []
        if(isChecked){
            let prev_state = this.state.fileList.map(file => file);
            files = prev_state
        }
        this.setState({isAllChecked: isChecked, selectedFiles: files}, ()=>{ this.sendSelectedFiles();})        
    }

    render() {
        const fileList = this.state.fileList;
        const selectedFiles = this.state.selectedFiles

        return (
            <>
                { 
                    fileList.length > 0 &&                        
                    <div style={{display:"inline-flex", margin: "0 auto", marginTop: "25px", maxWidth: "700px", width: "100%"}}>
                        <div className="onoffswitch">                  
                            <input type="checkbox" 
                                name="selectAllWells" 
                                className="onoffswitch-checkbox" 
                                id="selectAllWells" 
                                checked={ this.state.isAllChecked }
                                onChange={(event) => this.handlerSelectAllClick(event.target.checked)} 
                            />
                            <label className="onoffswitch-label" htmlFor="selectAllWells" style={{padding: 0}}></label>
                        </div>
                        <label style={{marginLeft:"5px", padding: "0", border: 0}}>                                    
                            Select/Deselect all
                        </label>
                    </div>
                }

                <div className='listFiles styled-scrollbars'>                    
                    {
                        fileList.map((data, idx) => {
                            return (                                
                                <label htmlFor={'chk-'+(idx+1).toString()} key={'chk-'+(idx+1).toString()}>
                                    <input type='checkbox' id={'chk-'+(idx+1).toString()} onChange={() => this.checkboxChange(data)} value={data.name} style={{marginRight: '5px'}} checked={ selectedFiles.includes(data) }/>
                                    {data.name}
                                </label>                                
                            )
                        }
                        )
                    }
                </div>
            </>        
          )
    }
}