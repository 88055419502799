import React, { Component, useEffect } from "react";
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './assets/navigation buttons/navigation buttons.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import RouteComponent from './components/Route/RouteComponent';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
// import {StandartColors} from './utils/enums/StandartColors';
import Hotjar from "./components/Hotjar/Hotjar";
import './assets/navigation buttons/navigation buttons.css'

class App extends Component {

  render() {
    // document.body.style.backgroundColor = StandartColors.DarkerWhite;
    return (     
      <>
        <ToastContainer
          position="top-right"
          autoClose={6000}
          hideProgressBar
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Hotjar/>
        <RouteComponent />
      </>   
      
    );
  }
  
}

export default App;
