import { RestApiService } from "../../services/RestApiService";
import { getProjectToken } from "../../utils/helpers/TokenHelper";

class FilesThread{

    url:string;
    file:any[];
    method:string;
    callback:any;
    error:string;

    constructor(file:any[], url:string, method:string, callback?:any){
        this.file = file;
        this.url = url;
        this.method = method;
        if(callback){
            this.callback = callback;
        }
        this.error = "";
    }

    public setError(error:string){
        this.error = error;
    }

    public getError(){
        return this.error;
    }

    public getFilesNames = ()=>{
        return this.file.map((currentFile)=>{return currentFile.name})
    }
}

export default class UploadManagement{

    private filesStack:FilesThread[];
    private completed:FilesThread[];
    private errors:FilesThread[];
    private currentUpload: RestApiService|null;
    private controller: AbortController;
    private abort: boolean;
    private nFiles:number;
    private onComplete:any|null;

    constructor(){
        this.filesStack = [];
        this.completed = [];
        this.errors = [];
        this.currentUpload = null;
        this.controller = new AbortController();
        this.abort = false;
        this.nFiles = 0;
        this.onComplete = null;
    }

    private eraseData(){
        this.filesStack = [];
        this.completed = [];
        this.errors = [];
        this.currentUpload = null;
        this.controller = new AbortController();
        this.abort = false;
        this.nFiles = 0;
        this.onComplete = null;
    }

    public addFile(file:any[], url:string, method:string, callback?:any):void{
        this.filesStack.push(new FilesThread(file,url,method));
    }

    private uploadNext():void{
        if(this.filesStack.length > 0 && !this.abort){
            const currentFile = this.filesStack.pop()
            if(currentFile){
                let data = new FormData()
                currentFile?.file.forEach((file)=>{
                    data.append("file", file)
                })
                let header = RestApiService.getHeader(getProjectToken())
                delete header['Content-Type'];

                fetch(currentFile.url, {
                    method: 'POST',
                    headers: header,
                    body: data,
                    signal:this.controller.signal
                }).then(response => {
                    if(response.status == 200 || response.status == 201){
                        if(currentFile.callback){
                            currentFile.callback()
                        }
                        this.completed.push(currentFile)
                    }
                    else{
                        console.error('Error posting data:', response.json());
                        currentFile.setError("Error in upload data")
                        this.errors.push(currentFile);
                    }
                    this.uploadNext()
                }).catch(error => {
                    console.error('Error posting data:', error);
                    currentFile.setError(error)
                    this.errors.push(currentFile);
                });
            }
        }
        else{
            console.log("UploadManagement", "Nothing to do");
            if(this.onComplete){
                this.onComplete()
            }
        }
    }

    public start(onComplete?:any):void{
        if(onComplete){
            this.onComplete = onComplete
        }
        if(this.filesStack.length > 0){
            this.nFiles = this.filesStack.length
            this.uploadNext()
        }
        else{
            console.log("UploadManagement", "Nothing to do");
        }
    }

    public stackFilesNames(){
        return this.filesStack.map((file)=>{return file.getFilesNames()})
    }

    public clear(){
        this.eraseData();
    }

    public filesNumber():number{
        return this.nFiles;
    }

    public missingFiles():number{
        return this.filesStack.length;
    }

    public completedNumber():number{
        return this.completed.length
    }

    public errorsNumber():number{
        return this.errors.length
    }

    public stop():void{
        this.controller.abort();
        this.abort = true
    }
}