import React, { Component } from 'react';
import './ParserFields.css'
import {ParserParam} from './ParserValues';
import { EventNames } from '../../utils/enums/EventNames';

interface ParserOptions{
    delimiter:ParserParam[];
    qualifier:ParserParam[];
    comment:ParserParam[];
    depthUnit:ParserParam[];
    depthType:ParserParam[];
    noValue:ParserParam[];
}

interface PaserProps{
    context:string;
    delimiter: string;
    qualifier: string;
    comment: string;
    startLine: number;
    depthUnit: string;
    depthType: string;
    noValue: string;
    options: ParserOptions;
    showFields?: any;
}

interface PaserState{
    delimiter: string;
    qualifier: string;
    comment: string;
    startLine: number;
    depthUnit: string;
    depthType: string;
    noValue: string;
    timerToSend:any,
}

class ParserFieldsComponent extends Component<PaserProps, PaserState> {

    updatedParserEvent: string = EventNames.updatedParserEvent;

    constructor(props: any) {
        super(props);
        this.state = {
            delimiter: this.props.delimiter,
            qualifier: this.props.qualifier,
            comment: this.props.comment,
            startLine: this.props.startLine,
            depthUnit: this.props.depthUnit,
            depthType: this.props.depthType,
            noValue: this.props.noValue,
            timerToSend:null
        }
    }

    /**
     * Set delimiter value.
     * @param value new delimiter value.
     */
    setDelimiter(value:string){
        this.setState({delimiter:value});
        this.waitToSaveConfs();
    }

    /**
     * Set qualifier value.
     * @param value new qualifier value.
     */
    setQualifier(value:string){
        this.setState({qualifier:value});
        this.waitToSaveConfs();
    }

    /**
     * Set comment value.
     * @param value new comment value.
     */
    setComment(value:string){
        this.setState({comment:value});
        this.waitToSaveConfs();
    }

    /**
     * Set Dept Unit value.
     * @param value new Dept Unit value.
     */
    setDepthUnit(value:string){
        this.setState({depthUnit:value});
        this.waitToSaveConfs();
    }

    /**
     * Set Start Line value.
     * @param value new Start Line value.
     */
    setStartLine(value:number){
        this.setState({startLine:value});
        this.waitToSaveConfs();
    }

    /**
     * Set Dept Type value.
     * @param value new Dept Type value.
     */
    setDepthType(value:string){
        this.setState({depthType:value});
        this.waitToSaveConfs();
    }

    /**
     * Set No Value.
     * @param value new No Value.
     */
    setNoValue(value:string){
        this.setState({noValue:value});
        this.waitToSaveConfs();
    }

    /**
     * Create a timer to send updated values
     */
    waitToSaveConfs = () => {
        if(this.state.timerToSend != null){
          clearTimeout(this.state.timerToSend);
        }
        var timer = setTimeout(this.saveConf,2000);
        this.setState({timerToSend:timer});
    }

    /**
    * Perform save configs
    */
    saveConf = () => {
        const customEvent = new CustomEvent(this.updatedParserEvent, { detail: { 
            delimiter: this.state.delimiter,
            qualifier: this.state.qualifier,
            comment: this.state.comment,
            startLine: this.state.startLine,
            depthUnit: this.state.depthUnit,
            depthType: this.state.depthType,
            noValue: this.state.noValue,
            context:this.props.context
        } });
        document.dispatchEvent(customEvent);
    }

    render(){ 
        return(
            <>
                <div className='parserFields'>                    
                    { (this.props.showFields == undefined || (this.props.showFields && this.props.showFields.delimiter != false)) && 
                        <div>
                            <label>
                                Delimiter
                            </label>
                            <select name="delimiter" value={this.state.delimiter} onChange={(event)=>{this.setDelimiter(event.target.value)}}>
                                {
                                    this.props.options.delimiter.map((data:ParserParam,idx)=>{
                                        return <option value={data.value} defaultChecked={this.state.delimiter==data.value}>{data.display}</option>
                                    })
                                }
                            </select>
                        </div>
                    }
                    {  (this.props.showFields == undefined || (this.props.showFields && this.props.showFields.qualifier != false)) && 
                        <div>
                            <label>
                                Qualifier
                            </label>
                            <select name="qualifier" value={this.state.qualifier} onChange={(event)=>{this.setQualifier(event.target.value)}}>
                                {
                                    this.props.options.qualifier.map((data:ParserParam,idx)=>{
                                        return <option value={data.value} defaultChecked={this.state.qualifier==data.value}>{data.display}</option>
                                    })
                                }
                            </select>
                        </div>
                    }                    
                    {  (this.props.showFields == undefined || (this.props.showFields && this.props.showFields.startLine != false)) && 
                        <div>
                            <label>
                                Start Line
                            </label>
                            <input type="number" min="1" value={this.state.startLine} onChange={(event)=>{if (parseInt(event.target.value) < 1) return false; this.setStartLine(parseInt(event.target.value))}} />
                        </div>
                    }
                    {  (this.props.showFields == undefined || (this.props.showFields && this.props.showFields.comment != false)) && 
                        <div>
                            <label>
                                Comment                            
                            </label>
                            <select name="Comment" value={this.state.comment} onChange={(event)=>{this.setComment(event.target.value)}}>
                                {
                                    this.props.options.comment.map((data:ParserParam,idx)=>{
                                        return <option value={data.value} defaultChecked={this.state.comment==data.value}>{data.display}</option>
                                    })
                                }
                            </select>
                        </div>
                    }
                    {/* {  (this.props.showFields == undefined || (this.props.showFields && this.props.showFields.DistanceUnit != false)) && 
                        <div>
                            <label>
                                Distance Unit
                            </label>
                            <select name="Distance" value={this.state.depthUnit} onChange={(event)=>{this.setDepthUnit(event.target.value)}}>
                                {
                                    this.props.options.depthUnit.map((data:ParserParam,idx)=>{
                                        return <option value={data.value} defaultChecked={this.state.depthUnit==data.value}>{data.display}</option>
                                    })
                                }
                            </select>
                        </div>
                    } */}
                    {  (this.props.showFields == undefined || (this.props.showFields && this.props.showFields.depth != false)) && 
                        <div>                        
                            <label>
                                Depth
                            </label>
                            <select name="Depth" value={this.state.depthType} onChange={(event)=>{this.setDepthType(event.target.value)}}>
                                {
                                    this.props.options.depthType.map((data:ParserParam,idx)=>{
                                        return <option value={data.value} defaultChecked={this.state.depthType==data.value}>{data.display}</option>
                                    })
                                }
                            </select>
                        </div>
                    }
                    {  (this.props.showFields == undefined || (this.props.showFields && this.props.showFields.noValue != false)) && 
                        <div>
                            <label>
                                No Value
                            </label>
                            <select name="noValue" value={this.state.noValue} onChange={(event)=>{this.setNoValue(event.target.value)}}>
                                {
                                    this.props.options.noValue.map((data:ParserParam,idx)=>{
                                        return <option value={data.value} defaultChecked={this.state.noValue==data.value}>{data.display}</option>
                                    })
                                }
                            </select>
                        </div>
                    }
                </div>                
            </>
        )
    }

}

export default ParserFieldsComponent;