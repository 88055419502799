import React, { Component } from 'react';
import "./style.css";
import { deleteProjects, getProjectHostInfo, getUserProjects, loadProject, newProject } from '../../services/projectService';
import { getUserInfo } from '../../utils/helpers/TokenHelper';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import DialogContentText from '@mui/material/DialogContentText';
import { Button } from '@mui/material';
import { FaTrashAlt } from "react-icons/fa";
import ToastHelper from '../../utils/helpers/ToastHelper';
import { getHostInfo } from '../../services/correlationService';
import { getWellHostInfo } from '../../services/wellService';
import { getUserHostInfo } from '../../services/userService';
import { getStratigraphicHostInfo } from '../../services/stratigraphicService';

interface Project{
    id: number,
    title: string,
    description: string,
    projectType:string
}

interface UserProjectViewState{
    currentProject: number,
    showRemoveDialog: boolean,
    currentRemoveProjectId: number,
    currentRemoveProjectName: string,
}

interface UserProjectViewProps{
    projects: Project[],
}

const PROJECTDEFAULTS:any = {
    autolog:{
        border: '1px solid #E1E3E7',
        borderLeft: '5px solid red',
        backgroundColor: "white",
        cursor:"pointer"
    }
}

interface ServiceStatus{
    name:string;
    buildDate:string;
}

interface VersionState{
    services: ServiceStatus[]
}

class VersionsView extends Component<{},VersionState>{

    constructor(props:any){
        super(props);
        this.state = {
            services:[]
        }
    }

    componentDidMount(): void {
        getHostInfo().then((response)=>{
            this.setStatus("Correlation Service", response)
        });
        getWellHostInfo().then((response)=>{
            this.setStatus("Well Service", response)
        });
        getUserHostInfo().then((response)=>{
            this.setStatus("User Service", response)
        });
        getStratigraphicHostInfo().then((response)=>{
            this.setStatus("Stratigraphic Service", response)
        });
        getProjectHostInfo().then((response)=>{
            this.setStatus("Project Service", response)
        });
    }

    setStatus = (serviceName:string, serviceStatus:any) =>{
        let services = this.state.services;
        let service = services.find((service)=>service.name == serviceName);
        if(!service){
            service = { name:serviceName, buildDate:"" }
            services.push(service)
        }
        service.buildDate = serviceStatus.cpu.service_status.Created;
        this.setState({services:services});
    }

    render(): React.ReactNode {
        return(
            <>
                <div className='row mt-3 pt-3'>
                    <div className='col offset-1'>
                        {this.state.services.map((service)=>{
                            return <div className='row project-item pt-3 pb-3'><div className='col-2'>{service.name}</div><div className='col'>{service.buildDate}</div></div>
                        })}
                    </div>
                </div>
            </>
        )
    }
}

const RELOADPROJECTSLISTEVENT = 'reloadProjectsListEvent'

class ProjectsView extends Component<UserProjectViewProps, UserProjectViewState>{

    constructor(props:any){
        super(props);
        this.state = {
            currentProject:-1,
            showRemoveDialog:false,
            currentRemoveProjectId:-1,
            currentRemoveProjectName:""
        };
    }

    setCurrentProject = (projectNumber:number) =>{
        this.setState({currentProject:projectNumber});
    }

    loadProject = (projectNumber:number) =>{
        loadProject(this.props.projects[projectNumber].id).then((response)=>{
            window.location.href = '/';
        });
    }



    deleteProject = (projectNumber:number) => {
        deleteProjects({projects:[projectNumber]}).then((response=>{
            if(response.status == 200){
                ToastHelper.success(`Project was removed.`);
            }
            else{
                ToastHelper.error("Error when remove project. Please, contact your administrator." , ()=>{
                    console.log(response);
                });
            }
            reloadProjectsEvent();
        })).catch(error=>{

        })
    }

    showRemoveDialog = (projectId:number, projectName:string) =>{
        this.setState({showRemoveDialog:true, currentRemoveProjectId:projectId, currentRemoveProjectName:projectName})
    }

    removeDialog = () =>{
        return(
            <Dialog
                open={this.state.showRemoveDialog}
                onClose={()=>{ this.setState({showRemoveDialog:false}) }}
                PaperProps={{
                component:"form",
                onSubmit: (event: React.FormEvent<HTMLFormElement>) =>{
                    event.preventDefault();
                    this.setState({showRemoveDialog:false}, ()=>{
                        this.deleteProject(this.state.currentRemoveProjectId);
                    })
                }
                }}
            >
                <DialogTitle>Remove project {this.state.currentRemoveProjectName}?</DialogTitle>
                <DialogContent>
                    <DialogContentText>this operation will remove this project, and this operation cannot undo, you sure?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.setState({showRemoveDialog:false})}}>No</Button>
                    <Button type='submit'>Yes, delete it!</Button>
                </DialogActions>
            </Dialog>
        )
    }

    render(): React.ReactNode {
        return(
            <>
            <div className='row mt-3'>
                <div className='col'>
                    <div className='row mt-3'><div className='col-1 offset-1'><h2>Recents</h2></div></div>
                    <div className='row mt-3' style={{ maxHeight:"500px", minHeight:"400px" }}> 
                        <div className='offset-1 col-11'>
                            {
                                this.props.projects.map((project:Project, projectIdx:number) => {
                                    return (
                                        <div className='row' style={{cursor:"pointer"}}>
                                            <div className='col'>
                                                <div className='row project-item'><div className='pt-3 pb-3 trashProject' onClick={(event)=>{this.showRemoveDialog(project.id, project.title)}}><FaTrashAlt /></div><div className='col-11 pt-3 pb-3' onClick={(event)=>{this.loadProject(projectIdx)}}>{project.title}</div></div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            {this.removeDialog()}
            </>
        )
    }

}

interface NewProjectViewProps{
}

interface NewProjectViewState{
    name:string,
    description:string,
}


class NewProjectFormView extends Component<NewProjectViewProps, NewProjectViewState>{

    constructor(props:any){
        super(props);
        this.state = {
            name:"",
            description:""
        };
    }

    setProjectName = (projectName:string) =>{
        this.setState({name:projectName});
    }

    setDescription = (description:string) =>{
        this.setState({description:description});
    }

    createProject = () =>{
        const dataset = {
            project_name:this.state.name,
            project_description:this.state.description,
        }
        newProject(dataset).then((response)=>{
            reloadProjectsEvent();
            window.location.href = '/';
        })
    }


    render(): React.ReactNode {
        return(
            <div style={{minWidth:'300px'}}>
                <div className='row'>
                    <div className='col'>
                        Name: <input onChange={(event)=>{this.setProjectName(event.target.value)}} />
                    </div>
                </div>
                <div className='row mt-2'>
                    <div className='col' style={{verticalAlign:'text-top'}}>
                        Description:
                    </div>
                    <div className='col-8'>
                        <textarea onChange={(event)=>{this.setDescription(event.target.value)}} />
                    </div>
                </div>
                <div className='row mt-1'>
                    <div className='col'>
                        <input type="button" className='btn btn-secondary' value="Create" disabled={this.state.name.trim() == ""} onClick={(event)=>{this.createProject()}}/>
                    </div>
                </div>
            </div>
        )
    }
}

const TABS = [
    { display:"Load a project" },
    { display:"Create a project" },
    { display:"About" },
    { display:"Version" },
    { display:"Help" },
]

interface UserProjectState{
    projects: Project[],
    currentTab:string,
    createProjectDialog:boolean,
}

interface UserProjectProps{

}

const RELOADPROJECTSEVENT = 'reloadprojectsevent'

function reloadProjectsEvent(){
    const customEvent = new CustomEvent(RELOADPROJECTSEVENT, { detail: {} 
    });
    document.dispatchEvent(customEvent);
  }

export default class UserProjects extends Component<UserProjectProps, UserProjectState> {
    constructor(props:any){
        super(props);
        this.state = {
            currentTab: TABS[0].display,
            projects: [],
            createProjectDialog:false
        }
        this.getProjects()
    }

    componentDidMount() {  
        document.addEventListener(RELOADPROJECTSEVENT, this.reloadProjects);
    }
    
    componentWillUnmount() {
        document.removeEventListener(RELOADPROJECTSEVENT, this.reloadProjects);
    }

    reloadProjects = (event:any) =>{
        if (event.type !== RELOADPROJECTSEVENT){
            return;
        }
        this.getProjects();
    }

    getProjects = () =>{
        getUserProjects().then((response)=>{
            let projects = []
            projects = response.map((project:any)=>{
                return {
                    id: project.project_id,
                    title: project.name,
                    description: project.description,
                    projectType: "autolog"
                }
            });
            this.setTab(projects.length > 0?0:1)
            this.setState({projects:projects});
        });
    }

    setTab = (tabIdx:number) =>{
        if(tabIdx != 1){
            this.setState({currentTab:TABS[tabIdx].display});
        }
        else{
            this.setState({createProjectDialog:true});
        }
    }
    
    showAbout = () =>{
        return(
            <div className='about'>
                <p>
                    Nautilus is a versatile solution with a web interface that can adapt to most geoscience workflows through a modular structure. Users can build  their workflow or use a built-in workflow dedicated to a specific  domain.
                </p>
                <p>
                    Autolog is Nautilus' automated solution for Multi-Well, Multi-Log Correlation, transforming the traditionally manual process of well log correlation. Designed to streamline and scale well analysis, Autolog precisely analyzes data across multiple wells, freeing geoscientists from time-consuming tasks and delivering fast, high-value results.
                </p>
            </div>
            
        )
    }

    renderCreateProjectDialog = () =>{
            return(
                <Dialog open={this.state.createProjectDialog} onClose={()=>{this.setState({createProjectDialog:false});}}>
                    <DialogTitle>Project Options</DialogTitle>
                    <DialogContent dividers>
                        <NewProjectFormView />
                    </DialogContent>
                </Dialog>
            )
    }

    render(): React.ReactNode {
        document.body.style.backgroundColor = "#000";
        return(
            <>
                <div className='projectPage'>
                    <div className='row nav-color'>
                        <div className='col-2' style={{borderRight:"1px solid #E1E3E7"}}>
                            <h1>Autolog</h1>
                        </div>
                        <div className='col'>
                            <nav className='navbar nav-color pt-0 pb-0'>
                                
                            </nav>
                        </div>
                    </div>
                    <div className='row' style={{height:"90vh"}}>
                        <div className='col-2' style={{borderRight:"1px solid #E1E3E7"}}>
                            <div className='row mt-3'>
                                <div className='col mt-3'>
                                    { TABS.map((tab:any,tabIdx:number)=>{
                                        return(
                                            <div className={`row ${tab.display == this.state.currentTab?'selected-menu-item':'' }`}><div className='col pt-2 pb-2' style={{cursor:'pointer'}} onClick={(event)=>{this.setTab(tabIdx)}}><b>{tab.display}</b></div></div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='row mt-3'><div className='col'><b>Welcome to Autolog, {getUserInfo().name}!</b></div></div>
                            <div className='row'>
                                <div className='col'>
                                    { (this.state.currentTab == TABS[0].display) && (<ProjectsView projects={this.state.projects} />) }
                                    {/* { this.state.currentTab == TABS[1].display && <NewProjectFormView /> } */}
                                    { this.state.currentTab == TABS[3].display && <VersionsView /> }
                                    { (this.state.currentTab == TABS[2].display) && (this.showAbout()) }
                                </div>
                            </div>
                        </div>
                    </div>
                        {this.renderCreateProjectDialog()}
                </div>                
            </>
        )
    }
}