//External packages
import React, { Component } from 'react';
import Switch from '@mui/material/Switch';
import isEqual from 'lodash.isequal';

// Internal Packages
import "./WellCorrelationsTab.css";
import { wellMetadata } from '../../../../../services/wellService';
import { StandartColors } from '../../../../../utils/enums/StandartColors';
import { CorrelationTabEvents } from '../../../../../utils/enums/CorrelationsTab';
import { getUserConfs, saveUserConfs } from '../../../../../services/projectService';
import ToastHelper from '../../../../../utils/helpers/ToastHelper';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { position } from 'html2canvas/dist/types/css/property-descriptors/position';

interface WellsSet{
    name:String,
    wellsNames: String[]
}

interface WellsCorrelationTabState {
    wellsNames: String[],
    wellsSelected: String[],
    wellsSets:WellsSet[],
    wellsNumberProperties: any[],
    currentWellSet: number,
    selectedAll:boolean,
    newWellSetName:string,
    timerToSend:any,
    prevState:any,
    metadata:any,
    firstTime:boolean,
}

interface WellsCorrelationTabProps {
    wellsNames:string[],
    propertiesSelected:string[],
}

class WellsCorrelationsTab extends Component<WellsCorrelationTabProps, WellsCorrelationTabState> {

    wellUpdateEvent: string = CorrelationTabEvents.wellUpdate;
    previousTabEvent: string = CorrelationTabEvents.previousTabEvent;
    nextTabEvent: string = CorrelationTabEvents.nextTabEvent; 

    constructor(props:any){
        super(props);
        this.state = {
            wellsNames: this.props.wellsNames,
            wellsSelected: [],
            wellsSets:[{name:"All Wells", wellsNames: []}],
            wellsNumberProperties: [],
            currentWellSet:0,
            selectedAll:false,
            newWellSetName:"",
            timerToSend:null,
            prevState:JSON.parse(JSON.stringify(this.props)),
            metadata:{},
            firstTime:true,
        }
        this.updateProperties();
    }
    componentDidMount(): void {
        this.selectAll(true)
    }

    updateProperties = () => {
        const well_metadata = wellMetadata();
        well_metadata.then((metadata)=>{
            const wells_names = Object.keys(metadata);
            this.getNumberPropertiesByWell(metadata);
            this.setState({wellsNames:wells_names, metadata:metadata}, ()=>{
                this.setTimerToSendChanges();
                this.loadWellsSets();
                // this.detectAllWellsSelected();
            });
        });
    }

    detectAllWellsSelected = () =>{
        if(this.state.wellsNames.length == this.state.wellsSelected.length){
            this.selectAll(true);
        }
        else{
            this.selectAll(false);
        }
    }

    componentDidUpdate(prevProps: Readonly<WellsCorrelationTabProps>, prevState: Readonly<WellsCorrelationTabState>, snapshot?: any): void {
        let update = false;
        if(!isEqual(this.props, this.state.prevState)){
            update = true;
        }
        if(update){
            this.setState({prevState:JSON.parse(JSON.stringify(this.props))},()=>{
                this.getNumberPropertiesByWell(this.state.metadata);
                if(this.state.firstTime && this.state.wellsSelected.length == 0){
                    
                    this.setState({firstTime:false},()=>{this.selectAll(true);});
                }
            })
        }

    }

    /**
     * Load Wells sets from User's config
     */
    loadWellsSets = () =>{
        const projectConf = getUserConfs();
        const allWells = JSON.parse(JSON.stringify(this.state.wellsNames))
        projectConf.then((response)=>{
            if( response.sets ){
                const sets = response.sets;
                var wellsSets:WellsSet[] = [{name:"All Wells", wellsNames: allWells}];
                if(sets.well){
                    Object.keys(sets.well).forEach((wellSet,setIdx)=>{
                        wellsSets.push({ name:wellSet, wellsNames:sets.well[wellSet].split(",") })
                    });
                }
                this.setState({wellsSets:wellsSets});
            }
        });
    }

    /**
     * Change Well Set.
     * @param wellSet Well Set Index.
     */
    changeWellSet = (wellSet:number) =>{
        let selectedAll = this.state.wellsSets[wellSet].wellsNames.length == this.state.wellsNames.length;
        const wellsNames = JSON.parse(JSON.stringify(this.state.wellsSets[wellSet].wellsNames))
        this.setState({currentWellSet:wellSet, wellsSelected:wellsNames, selectedAll:selectedAll},()=>{
            this.sendConfs()
        });
    }

    /**
     * Save current well set.
     */
    saveWellSet = () =>{
        // let dataset: {[index: string]:any} = {sets:{well:{}}};
        // dataset.sets.well[`${this.state.newWellSetName}`] = this.state.wellsNames.toString();
        // console.log("Help leon!", dataset);
        if(this.state.newWellSetName !== ""){
            saveUserConfs([{"topic":"sets", "group":"well", "key": `${this.state.newWellSetName}`, "value":this.state.wellsSelected.toString()}]).then((response)=>{
                this.loadWellsSets()
            });
        }
        else{
            alert("Well set name field cannot set empty");
        }
        
    }
    

    getNumberPropertiesByWell = (metadata:any) => {
        let wellsNumbersProperties: any[] = []
        Object.keys(metadata).forEach((column:any)=>{
            let properties = metadata[column]["curves"]?Object.keys(metadata[column]["curves"]["versions"][0]["curves_info"]):[]
            properties = properties.filter((property)=> this.props.propertiesSelected.includes(property))
            const numberProperties = properties.length;
            wellsNumbersProperties.push(
            {
                well: column,
                numberProperties: numberProperties
            })
        });

        this.setState({wellsNumberProperties: wellsNumbersProperties})
    }


    checkOption =(event:any, check_name:string) =>{
        let checked = event.target.checked;
        let wellNames = this.state.wellsSelected;
        if(checked){
            wellNames.push(check_name);
        }
        else{
            wellNames.splice(wellNames.indexOf(check_name),1);
        }
        let selectedAll = this.state.wellsSelected.length == this.state.wellsNames.length;
        this.setState({wellsSelected:wellNames, selectedAll:selectedAll});
        this.setTimerToSendChanges()
    }

    setTimerToSendChanges = () => {
        if(this.state.timerToSend != null){
            clearTimeout(this.state.timerToSend);
          }
          var timer = setTimeout(this.sendConfs,100);
          this.setState({timerToSend:timer});
    }

    sendConfs = () =>{
        const customEvent = new CustomEvent(this.wellUpdateEvent, { detail: {
            wellNames:this.state.wellsSelected,
            currentWellSet: this.state.currentWellSet
        } 
        });
        document.dispatchEvent(customEvent);
    }

    showWellNames = () =>{
        return(
            <>
                <TableContainer>
                    <Table>
                        <TableHead className='backgroundColorBlack'>
                            <TableRow>
                                <TableCell>
                                    <b>Select a well/s from {this.state.wellsSets[this.state.currentWellSet].name}:</b>                                
                                    <div>
                                        <div className="onoffswitch">                  
                                            <input type="checkbox" 
                                                name="selectAllWells" 
                                                className="onoffswitch-checkbox" 
                                                id="selectAllWells" 
                                                checked={this.state.selectedAll} 
                                                onChange={(event)=>{this.selectAll(event.target.checked)}}
                                            />
                                            <label className="onoffswitch-label" htmlFor="selectAllWells"></label>
                                        </div>
                                        <label>                                    
                                            Select/Deselect all
                                        </label>
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.wellsNames.map((well_name:any,idx:any)=>{
                                return (
                                    <TableRow>
                                        <TableCell>
                                            <label className='correlationLabel'>                        
                                                <input type='checkbox' 
                                                    onChange={(event)=>{this.checkOption(event, well_name);}}                
                                                    checked={this.state.wellsSelected.includes(well_name)}
                                                    defaultChecked={true}                                                
                                                />
                                                {this.state.wellsNumberProperties.map((well)=>{
                                                    if(well_name == well.well){
                                                        if(well.numberProperties <= 1){
                                                            return (`${well.well} (${well.numberProperties} property)`)
                                                        }
                                                        return (`${well.well} (${well.numberProperties} properties)`)
                                                    }                                
                                                })}                            
                                            </label>
                                    </TableCell>
                                </TableRow>);
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>            
            </>
        )
    }

    selectAll = (selected:boolean) =>{
        let wells_names:string[] = this.state.wellsNumberProperties.filter((well)=> well.numberProperties>0).map((well)=>well.well)
        if(selected && wells_names.length > 0){
            // let well_names = JSON.parse(JSON.stringify(this.state.wellsNames));
            this.setState({wellsSelected:wells_names, selectedAll:true});
        }
        else{
            this.setState({wellsSelected:[], selectedAll:false});
        }
        this.setTimerToSendChanges();
    }

    changeNewWellSetName = (event:any) =>{
        this.setState({newWellSetName:event.target.value});
    }

    render(): React.ReactNode {
        return(
            <>
                <div className='selectToCorrelate'>                    
                    <label>
                        Select the wells to correlate:
                        <span>
                            From:&nbsp; 
                            <select id="wellSet" onChange={(event)=>{this.changeWellSet(parseInt(event.target.value))}}>
                                { this.state.wellsSets.map( (wellSet,setIdx)=>{
                                    return( <option value={setIdx} defaultChecked={setIdx==0}>{wellSet.name}</option> )
                                } ) }
                            </select>

                        </span>
                    </label>                    
                </div>
                
                <div className='table-container styled-scrollbars'>
                    {this.showWellNames()}
                </div>                
                
                <div className='saveTemplate'>
                    <div className='field'>
                        <label> Save as a template (optional):</label>
                        <input onChange={(event:any) => {this.changeNewWellSetName(event)}} value={this.state.newWellSetName} placeholder='template name'/>
                    </div>
                    
                    <input type="button" value="Save" className='save-button' onClick={()=>{this.saveWellSet()}} />
                </div>                           
            </>
        )
    }

}

export default WellsCorrelationsTab;