import React, { Component } from 'react';
import Papa from "papaparse";
import { EventNames } from '../../utils/enums/EventNames';
import ParserFieldsComponent from '../ParserFields/ParserFields';
import ParserParameters from '../ParserFields/ParserValues';
import PreviewTableComponent, {setTableBody} from '../PreviewTable/PreviewTableComponent';
import { AttributeParam } from '../PreviewTable/TableOptions';
import ToastHelper from '../../utils/helpers/ToastHelper';

interface SelectDataComponentProps {
    context: string;
    headersColumns: any;
    defaultColumns: any;
    delimiter: string;
    qualifier: string;
    comment: string;
    depthUnit: string;
    depthType: string;
    noValue: string;
    listHeaderOptions: AttributeParam[];
}

interface SelectDataComponentState {
  fileList: any[];  
  startLine: number;
  delimiter: string;
  qualifier: string;
  comment: string;
  depthUnit: string;
  depthType: string;
  noValue: string;
  headersColumns: any;
  defaultColumns: any;
  dataList: any[];
  newWellsNames: any;
  fileData: any[]
}

class SelectDataComponent extends Component<SelectDataComponentProps, SelectDataComponentState> {  
  updatedParserEvent: string = EventNames.updatedParserEvent;
  changeWellsNamesEvent = EventNames.updatedWellNamesEvent;
  previewTableChangedEvent: string = EventNames.previewTableChangedEvent;
  SelectDataComponentEvent: string = EventNames.SelectDataComponentEvent;  
  SelectDataComponentClickUploadButton: string = EventNames.SelectDataComponentClickUploadButton;  
  SelectDataComponentParserInfoEvent: string = EventNames.SelectDataComponentParserInfoEvent;
  loadFileComponentEvent: string = EventNames.LoadFileComponentEvent;

  constructor(props: any) {
    super(props);
    this.state = {      
      fileList: [],
      startLine: 1,
      delimiter:this.props.delimiter,
      qualifier:this.props.qualifier,
      comment:this.props.comment,
      depthUnit:this.props.depthUnit,
      depthType:this.props.depthType,
      noValue:this.props.noValue,
      headersColumns:this.props.headersColumns,
      defaultColumns: this.props.defaultColumns,
      dataList:[],
      newWellsNames:{},
      fileData:[],
      // csvParserStateDict: {},
    };
  }

  componentDidMount() {
    document.addEventListener(this.updatedParserEvent, this.updatedParserParameters);
    document.addEventListener(this.changeWellsNamesEvent, this.fixWellsNames);
    document.addEventListener(this.previewTableChangedEvent, this.changeColumn);
    document.addEventListener(this.loadFileComponentEvent, this.setFiles);
  }

  componentWillUnmount() {    
    document.removeEventListener(this.updatedParserEvent, this.updatedParserParameters);
    document.removeEventListener(this.changeWellsNamesEvent, this.fixWellsNames);
    document.removeEventListener(this.previewTableChangedEvent, this.changeColumn);    
    document.removeEventListener(this.loadFileComponentEvent, this.setFiles);
  }

  setFiles = (event:any) => {
    if (event.type !== this.loadFileComponentEvent && event.detail.context != this.props.context){
      return;
    }

    const dataset = event.detail;
    this.setState({fileList:dataset.fileList},()=>{
      this.parseFiles()
    });
  }

  updateDataset = () => {
    const customEvent = new CustomEvent(this.SelectDataComponentEvent, { detail: {
      headersColumns:this.state.headersColumns,
      dataList: this.state.dataList,
      context: this.props.context   
    }});
    document.dispatchEvent(customEvent);
  }

  uploadClickHandler = () => {
    const customEvent = new CustomEvent(this.SelectDataComponentClickUploadButton, { detail: {
      context: this.props.context   
    }});
    document.dispatchEvent(customEvent);
  }

  changeColumn =(event:any)=>{
    if (event.type !== this.previewTableChangedEvent && event.detail.context != this.props.context){
      return;
    }
    let dataset = event.detail.columnNames;
    let prev_state = this.state.headersColumns;
    Object.keys(prev_state).map((prop)=>{
      let newIdx = dataset.findIndex((key:string) => key == prop)
      if(newIdx <= 0){
        newIdx = undefined
      }
      prev_state[prop] = newIdx;
    })

    // dataset.forEach((value:string, idx:number)=>{
    //   if(Object.keys(prev_state).includes(value)){
    //     prev_state[value] = idx;
    //   }
    // });
    this.setState({headersColumns:prev_state}, ()=>{
      let column_values = this.fixWellsInfos(JSON.parse(JSON.stringify(this.state.fileData)));
      this.setState({dataList:column_values}, ()=>{this.updateDataset();});      
    })
    
    //updateTable(column_values, this.props.context);
    
  }

  fixWellsInfos = (prev_data:any[], filename?:string) =>{

    prev_data = prev_data.filter((line, idx)=>{
      if(idx + 1 >= this.state.startLine){
        return line  
      }      
    })

    let wells_infos = prev_data.map((data:any,dataIdx:number, prev_arr:any[])=>{
      let set_filename = "";
      if(dataIdx<this.state.dataList.length){
        set_filename = filename?filename:this.state.dataList[dataIdx][0];
      }
      else{
        set_filename = filename ? filename : this.state.dataList[0][0]
      }
      let machlee_name = this.state.headersColumns.well != undefined ? data[this.state.headersColumns.well] : set_filename.split(".")[0];
      if( prev_arr[this.state.headersColumns.well] ){
        machlee_name = prev_arr[dataIdx][this.state.headersColumns.well];
      }
      // if(this.state.headersColumns.well && this.state.dataList.length > 0 && this.state.dataList[dataIdx]){
      //   machlee_name = this.state.dataList[dataIdx][2];
      // }
      let nautilusName = this.state.headersColumns.well ? data[this.state.headersColumns.well] : set_filename.split(".")[0];
      if( this.state.dataList[this.state.headersColumns.well] ){
        nautilusName = prev_arr[dataIdx][this.state.headersColumns.well];
      }
      if( prev_arr[dataIdx][1] && !prev_arr[dataIdx][1].startsWith(this.state.comment) ){
        return [set_filename, nautilusName, machlee_name];
      }
      return[set_filename, undefined, undefined]
    }).filter((line)=>{
      return line[1] || line[2] > 0;
    })

    if(wells_infos.length > 0){
      wells_infos = wells_infos.filter((row, idx, infosArray) => {
        const prev_data = infosArray.map( line => line[1])
        return prev_data.indexOf(row[1]) == idx
      })
    }

    return wells_infos;
  }

  fixWellsNames =(event:any) => {
    if (event.type !== this.updatedParserEvent && event.detail.context != this.props.context){
      return;
    }
    let dataset = event.detail;
    let prevState = this.state.newWellsNames;
    prevState = this.processNewWellsNames(dataset.wellInfosContents,prevState);
    this.setState({newWellsNames:prevState});
  }

  processNewWellsNames (dataset:any, prevState:any){
    dataset.forEach((row:string[],rowIdx:number)=>{
      if(!Object.keys(prevState).includes(row[0])){
        prevState[row[0]] = {};
      }
      if(!prevState[row[0]][row[1]]){
        prevState[row[0]][row[1]] = row[2];
      }
    })
    return prevState;
  }

  updatedParserParameters = (event:any) => {
    if (event.type !== this.updatedParserEvent && event.detail.context != this.props.context){
      return;
    }
    let dataset = event.detail;
    this.setState({
      delimiter:dataset.delimiter,
      qualifier:dataset.qualifier,
      comment:dataset.comment,
      startLine:dataset.startLine,
      depthUnit:dataset.depthUnit,
      depthType:dataset.depthType,
      noValue:dataset.noValue,
    }, ()=>{this.parseFiles()});
  }

  removeEmptyColumnsBeginEndLine = (data:any) =>{
    data = data.split("\n")      
    let new_data = data.map((row:any) =>{
      return row.toString().trim()         
    })
    return new_data.join("\n");
  }

  getRawData = (new_result:any) =>{
    let rawData = this.state.fileData
    const wellHeaderColumnIndex = this.state.headersColumns['well']

    new_result.map((row:any) =>{
      let hasRow = false
      rawData.map((rowRawData) => {
        if(rowRawData[wellHeaderColumnIndex] == row[wellHeaderColumnIndex]){
          hasRow = true;
        }        
      }) 
      if(!hasRow){
        rawData = rawData.concat([row]) 
      }     
    })
    return rawData
  }

  removeEmptyColumns = (new_result:any) => {
    let newData:any[] = []
    new_result.forEach((line:any) => {
      let newLine = []
      newLine = line.filter((element: any) => { 
        return element.length > 0; 
      });

      newData.push(newLine)
    });
    
    return newData
  }

  parseFiles(){
    this.setState({dataList:[]});
    if(this.state.fileList.length > 0) {
      this.state.fileList.forEach((file,fileIdx)=>{
        Papa.parse(file, {
          header:false,
          //columns: [],
          download: true,
          // worker: true,
          dynamicTyping: false,
          delimiter:this.state.delimiter,
          quoteChar:this.state.qualifier,
          beforeFirstChunk: (data:any) =>{
            let new_data = data.replace(/  +/g, ' ');
            new_data = this.removeEmptyColumnsBeginEndLine(new_data);
            return new_data;
          },
          complete:(results:any) =>{              
            let new_result = results.data.filter((line:string[],idx:number)=>{
              //if(idx >= this.state.startLine && line.length > 1 && !line[0].startsWith(this.state.comment)){
              // if(idx >= this.state.startLine && line.length >= 1 && !line[0].startsWith(this.state.comment)){
              // if(line.length >= 1 && ((idx == 0 && this.state.startLine == 1) || idx >= this.state.startLine)){
              // if(line.length >= 1 && idx >= this.state.startLine - 1){
              if(line.length >= 1){
                return line.unshift(String(idx+1)) 
              }

            })

            // new_result = this.removeEmptyColumns(new_result);

            if(new_result.length < 0){
              new_result = [[]]
            }
            //let prevState = this.state.dataList;
            let prevState = this.fixWellsInfos(new_result,file.name);
            //prevState = prevState.concat(wells_infos);
            let newsInfos = this.processNewWellsNames(prevState,this.state.newWellsNames);
            //let raw_data = this.getRawData(new_result)
            setTableBody(this.props.context, new_result);
            this.setState({dataList:prevState,newWellsNames:newsInfos,fileData:new_result});
            this.parserInfoEvent(prevState, newsInfos, new_result);
            if(results.errors.length > 0 && results.errors[0].type == "Quotes"){
              ToastHelper.error("O Qualifier apresenta erro de interpretação. Por favor, verifique os campos no arquivo.","Erro ao ler o arquivo.");
            }
          }
        });
      })
    } else {
      this.setState({dataList:[],newWellsNames:[],fileData:[]});
      this.parserInfoEvent([], [], []);
      setTableBody(this.props.context, []);
    }    
  }

  parserInfoEvent = (dataList: any, newWellsNames: any, fileData: any) => {
    const customEvent = new CustomEvent(this.SelectDataComponentParserInfoEvent, { detail: {
      context: this.props.context,
      dataList: dataList,
      newWellsNames: newWellsNames,
      fileData: fileData,
      delimiter:this.state.delimiter,
      qualifier:this.state.qualifier,
      comment:this.state.comment,
      startLine:this.state.startLine,
      depthUnit:this.state.depthUnit,
      depthType:this.state.depthType,
      noValue:this.state.noValue,      
    }});
    document.dispatchEvent(customEvent);
  }

  CheckElementExistsInWellsInfos = (line:any[], idx:any, listWellsInfos:any[]) => {
    let prev_data = listWellsInfos.map((currentLine) => currentLine[1])
    return prev_data.indexOf(line[1]) == idx
  }


  render(){
    return(
      <>
        <div>
          <h3>Define the file reading options:</h3>            
        </div>
        
        <div>
            <ParserFieldsComponent
            context={this.props.context}
            delimiter={this.state.delimiter}
            qualifier={this.state.qualifier}
            comment={this.state.comment}
            startLine={this.state.startLine}
            depthUnit={this.state.depthUnit}
            depthType={this.state.depthType}
            noValue={this.state.noValue}
            options={{
                delimiter: new ParserParameters().delimiters,
                qualifier:new ParserParameters().qualifiers,
                comment:new ParserParameters().comments,
                depthUnit: new ParserParameters().DistanceUnit,
                depthType:new ParserParameters().DeptType,
                noValue: new ParserParameters().NoValues,
            }}
            />
        </div>
        <div className='associateLabel'>
            Associate well data to file columns:            
            {/* <div className='col-2'>
            <input type="button" className="btn btn-dark" value="Load" onClick={()=>{this.uploadClickHandler()}} />
            </div> */}
        </div>
        <div className='table-container styled-scrollbars'>
            <PreviewTableComponent                      
              listBody={[[]]}
              listHeaderOptions={ this.props.listHeaderOptions}            
              context={this.props.context}
              listDefaultColumns = {this.props.defaultColumns}
              startLine={this.state.startLine}
            />
        </div>
      </>
    )
  }

}

export default SelectDataComponent;
