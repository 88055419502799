import React, { Component } from 'react';
import { saveUserConfs } from '../../../services/projectService';
import { wellMetadata } from '../../../services/wellService';
import { EventNames } from "../../../utils/enums/EventNames";
// import PersonalizeWellTrackColors from "../../ConfirmDialog/PersonalizeWellTrackColors";
import { getUserConfs } from '../../../services/projectService';
import ReactModal from 'react-modal';
import { IoMdEye, IoMdEyeOff} from "react-icons/io";
import { ActionsTabTypes } from "../../../utils/enums/ActionsTabTypes";
import { ViewTypeTabName } from '../../../utils/enums/ViewTypeTabName';


interface SettingsTabComponentState {
  wellViewState:WellViewStates,
  wellTracksColorTemporary:any,
  wellTracksColor:any,
  showModal:boolean,
  logNames:any[],
  timerToSend:any,
  curvesNames: string[],
  selectedCurveEdit: string,
  viewName: string,
}
interface WellViewStates{
  trackWidth:any,
  trackLinesThickness:any,
  trackgap:any,
  trackborderColor:any,
  trackheadersborderColor:any,
  trackFontSize:any,
  trackFontName:any,
  trackShowZoomBar:boolean,
  trackShowVerticalScale:boolean,
  trackHeight:number,
  correlationDepth:string,
  wellDepth:string,  
}

interface SettingsTabComponentProps{
  activeConfigTab:string,
  viewName:string,
}

const well_depth = [
  {key:"RGT", show:"RGT"},
  {key:"MD", show:"MD"},
  {key:"TVD", show:"TVD"},
  {key:"TVDSS", show:"TVDSS"}
];

const depthsNames = [
  well_depth[0].key,
  well_depth[1].key,
  well_depth[2].key,
  well_depth[3].key
]

const refreshConfig = "RefreshConfigSidebar"

/**
 * Event to change Correlation data in Correlation View Tab.
 */
export function updateConfig(){
  const customEvent = new CustomEvent(refreshConfig, { detail: {} });
  document.dispatchEvent(customEvent);
}

class SettingsTabComponent extends Component<SettingsTabComponentProps, SettingsTabComponentState> {

  updateSettingsEvent: string = EventNames.refreshWellTrackPlotEvent;
  changeCurveColorsEvent: string = EventNames.wellColorsConfigEvent;
  changeTabEvent2: string = EventNames.changeTabEvent2;  

  constructor(props: any) {
    super(props);
    this.state = {
      showModal:false,
      logNames:[],
      curvesNames:[],
      selectedCurveEdit: '',
      viewName: "Well View",
      wellTracksColorTemporary:{
      },
      wellTracksColor:{},
      wellViewState:{
        trackWidth:200,
        trackLinesThickness:1,
        trackgap:10,
        trackborderColor:'#EEEFF0',
        trackheadersborderColor:'#EEEFF0',
        trackFontSize:11,
        trackFontName:"Arial",
        trackShowZoomBar:true,
        trackShowVerticalScale:true,
        trackHeight:700,
        correlationDepth:well_depth[0].key,
        wellDepth:"MD",        
      },
      timerToSend:null
    }
    this.refreshConfig()
  }

  refreshConfig = () =>{
    getUserConfs().then((userConfs)=>{
      if(!userConfs.plotView){
        userConfs = {
          plotView:{},
          userConfs:{}
        }
      }
      // Cleanup this code
      if(userConfs.plotView.plotViewConf){
        userConfs.plotView.plotViewConf.trackShowZoomBar = userConfs.plotView.plotViewConf.trackShowZoomBar=="true" ? true : false
        userConfs.plotView.plotViewConf.trackShowVerticalScale = userConfs.plotView.plotViewConf.trackShowVerticalScale=="true" ? true : false        
      }

      if(!userConfs.plotView.plotViewConf){
        userConfs.plotView.plotViewConf = {
          trackWidth:200,
          trackLinesThickness:2,
          trackgap:10,
          trackborderColor:'#EEEFF0',
          trackheadersborderColor:'#EEEFF0',
          trackFontSize:11,
          trackFontName:"Arial",
          trackShowZoomBar:true,
          trackShowVerticalScale:true,
          trackHeight:700,
          correlationDepth: "RGT"
        }
      }
      this.setState({
        wellTracksColor: userConfs.wellTracksColor?userConfs.wellTracksColor:{},
        wellTracksColorTemporary: userConfs.wellTracksColor?userConfs.wellTracksColor:{},
        wellViewState: userConfs.plotView.plotViewConf,
      })
    })
  }

  componentDidMount() {
    document.addEventListener(this.changeCurveColorsEvent, this.colorConfig);
    document.addEventListener(refreshConfig, this.refresh);
    document.addEventListener(this.changeTabEvent2, this.receiveViewName);
    this.createInitialColors();
  }

  componentWillUnmount() {
    document.removeEventListener(this.changeCurveColorsEvent, this.colorConfig);
    document.removeEventListener(refreshConfig, this.refresh);
    document.removeEventListener(this.changeTabEvent2, this.receiveViewName);
  }

  receiveViewName = (event:any) => {        
    if (event.type !== this.changeTabEvent2){
        return;
    }
    let viewName = event.detail.tabId;
    this.setState({viewName: viewName});
}   

  createColor = () => {
    // The available hex options
    let hex = ['a', 'b', 'c', 'd', 'e', 'f', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

    /**
     * Randomly shuffle an array
     * https://stackoverflow.com/a/2450976/1293256
     */
    let shuffle = (hex:any) => {

        let currentIndex = hex.length;
        let temporaryValue, randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = hex[currentIndex];
            hex[currentIndex] = hex[randomIndex];
            hex[randomIndex] = temporaryValue;
        }

    };

    /**
     * Create a six-digit hex color
     */
    let hexColor = () => {
        // Create the color
        let color = '#';

        // Shuffle the hex values and append
        for (let i = 0; i < 6; i++) {
            shuffle(hex);
            color += hex[0];
        }

        return color;
    };

    // Return the color string
    return hexColor();
  }

  fillCurveNames(){
    wellMetadata().then((response)=>{
      const curveColorsTemporary: {[index: string]:any} = this.state.wellTracksColorTemporary;
      const curveColors: {[index: string]:any} = this.state.wellTracksColor;
      let curvesNames: string[] = []
      Object.keys(response).forEach((wellName:any, idx)=>{
        Object.keys(response[wellName].curves.versions[0].curves_info).forEach((logName:any,logIdx)=>{
          if(!curvesNames.includes(logName)){
            curvesNames.push(logName) 
          } 
          if(!curveColors[logName]){
            /** Set colors here! */
            const color = this.createColor();            
            curveColors[logName] = {line: color, fill: color}
            curveColorsTemporary[logName] = {line: color, fill: color}
          }
        })
      })
      this.setState({logNames:Object.keys(curveColors),wellTracksColorTemporary:curveColorsTemporary, wellTracksColor:curveColors, curvesNames: curvesNames},()=>{
        this.setState({showModal:true},()=>{
          this.render()          
        })
      })
    })
  }

  createInitialColors = () => {
    wellMetadata().then((response)=>{
      const curveColorsTemporary: {[index: string]:any} = this.state.wellTracksColorTemporary;
      const curveColors: {[index: string]:any} = this.state.wellTracksColor;
      let curvesNames: string[] = []
      Object.keys(response).forEach((wellName:any, idx)=>{
        if(response[wellName].curves){
          Object.keys(response[wellName].curves.versions[0].curves_info).forEach((logName:any,logIdx)=>{ 
            if(!curvesNames.includes(logName)){
              curvesNames.push(logName) 
            }                      
            if(!curveColors[logName]){
              /** Set colors here! */
              const color = this.createColor();              
              curveColors[logName] = {line: color, fill: color}
              curveColorsTemporary[logName] = {line: color, fill: color}
            }
          })
        }
      })
      this.setState({logNames:Object.keys(curveColors),wellTracksColorTemporary:curveColorsTemporary, wellTracksColor:curveColors, curvesNames: curvesNames},()=>{
        this.saveConf();
      })
    })
  }

  colorConfig = (event:any) =>{
    if (event.type !== this.changeCurveColorsEvent){
      return;
    }
    this.fillCurveNames();
  }

  refresh = (event:any) =>{
    if (event.type !== refreshConfig){
      return;
    }
    this.createInitialColors();
  }

  setColors = (save:boolean) =>{
    // if(save){
    //   this.setState({wellTracksColor:this.state.wellTracksColorTemporary},()=>{
    //     // var show = this.state.wellTracksColor;
    //     this.saveConf();
    //   });
    // }
    // else {
    //   var prevState = this.state.wellTracksColor;
    //   this.setState({wellTracksColorTemporary:JSON.parse(JSON.stringify(prevState))},()=>{
    //     this.render();
    //   });
    // }    
    this.setState({wellTracksColor:this.state.wellTracksColorTemporary},()=>{      
      // var show = this.state.wellTracksColor;
      this.saveConf();
    });
  }

  setTrackTemporaryColor = (logName:any, colorType:any, colorValue:String) => {
    let prevState = this.state.wellTracksColorTemporary;
    prevState[logName][colorType] = colorValue;
    this.setState({wellTracksColorTemporary:prevState},()=>{
      this.setColors(true);
      this.render();
    })
  }

  waitToSaveConfs = () => {
    if(this.state.timerToSend != null){
      clearTimeout(this.state.timerToSend);
    }
    var timer = setTimeout(this.saveConf,1000);
    this.setState({timerToSend:timer});
  }

  /**
   * Perform save configs
   */
  saveConf = () => {        
    var dataset:any[] = []
    // Iterate in well view states
    Object.entries(this.state.wellViewState).forEach((value,idx) => {
      dataset.push({"topic":"plotView","group":"plotViewConf","key":value[0], "value":value[1].toString()});
    });
    Object.entries(this.state.wellTracksColor).forEach((value:any,idx) => {
      Object.entries(value[1]).forEach((fillType,fillTypeIdx)=>{
        dataset.push({"topic":"wellTracksColor","group":value[0],"key":fillType[0], "value":fillType[1]});
      })
    });
    // Send event to update view
    const customEvent = new CustomEvent(this.updateSettingsEvent, { detail: { settings:this.state } 
    });
    document.dispatchEvent(customEvent);    
    // Save new conf
    saveUserConfs(dataset).then((response)=>{
      this.refreshConfig();
      this.setState({timerToSend:null});
    }).catch((error)=>{
      this.setState({timerToSend:null});
    });       
  }

  setTrackWidth = (event:any) => {
    var state = this.state.wellViewState;
    state.trackWidth = parseInt(event.target.value)
    this.setState({wellViewState:state},()=>{this.waitToSaveConfs();});
  }
  setTrackLinesThickness = (event:any) => {
    var state = this.state.wellViewState;
    state.trackLinesThickness = parseInt(event.target.value)
    this.setState({wellViewState:state},()=>{this.waitToSaveConfs();});
  }
  setTrackGap = (event:any) => {
    var state = this.state.wellViewState;
    state.trackgap = parseInt(event.target.value)
    this.setState({wellViewState:state},()=>{this.waitToSaveConfs();});
  }
  setTracksBorderColor = (event:any) => {
    var state = this.state.wellViewState;
    state.trackborderColor = event.target.value
    this.setState({wellViewState:state},()=>{this.waitToSaveConfs();});
  }
  setTracksHeaderBorderColor = (event:any) => {
    var state = this.state.wellViewState;
    state.trackheadersborderColor = event.target.value
    this.setState({wellViewState:state},()=>{this.waitToSaveConfs();});
  }
  setTracksfontsType = (event:any) => {
    var state = this.state.wellViewState;
    state.trackFontName = event.target.value
    this.setState({wellViewState:state},()=>{this.waitToSaveConfs();});
  }
  setWellDepthType = (event:any) => {
    var state = this.state.wellViewState;
    state.wellDepth = event.target.value
    this.setState({wellViewState:state},()=>{this.waitToSaveConfs();});
  }
  setCorrelationDepthType = (event:any) => {
    var state = this.state.wellViewState;
    state.correlationDepth = event.target.value
    this.setState({wellViewState:state},()=>{this.waitToSaveConfs();});
  }
  setTracksfontsSize = (event:any) => {
    var state = this.state.wellViewState;
    state.trackFontSize = parseInt(event.target.value);
    this.setState({wellViewState:state},()=>{this.waitToSaveConfs();});
  }
  setShowVerticalScale = (event:any) => {
    var state = this.state.wellViewState;
    state.trackShowVerticalScale = event.target.checked;
    this.setState({wellViewState:state},()=>{this.waitToSaveConfs();});
  }
  setShowTracksZoom = (event:any) => {
    var state = this.state.wellViewState;
    state.trackShowZoomBar = event.target.checked;
    this.setState({wellViewState:state},()=>{this.waitToSaveConfs();});
  }
  setTracksHeight = (event:any) => {
    var state = this.state.wellViewState;
    state.trackHeight = parseInt(event.target.value);
    this.setState({wellViewState:state},()=>{this.waitToSaveConfs();});
  }

  getFontsTypeView = () => {
    var fontNames = ["Roboto"]
    return <>
      <select name="fontType" onChange={(event)=>{this.setTracksfontsType(event)}}>
        {fontNames.map((value:string,idx:any)=>{
          var checked = false
          if(value == this.state.wellViewState.trackFontName){
            checked = true
          }
          return <option key={idx} value={value} defaultChecked={checked}>{value}</option>
        })}
      </select>
    </>
  }

  getCurvesList = () => {    
    return <>
      <div className='listItems styled-scrollbars'>
      {
        this.state.curvesNames.map((curveName:string)=>{
          return <p><span>{curveName}</span><span className="button"><input type="checkbox" name={curveName+"-edit"} className="eye-checkbox" id={curveName+"-edit"} checked={this.state.selectedCurveEdit === curveName} onChange={() => this.setState({selectedCurveEdit: curveName})}/><label className="eye-label" htmlFor={curveName+"-edit"}>{this.state.selectedCurveEdit === curveName? <IoMdEye/> : <IoMdEyeOff/> }</label></span></p>
        })
      } 
      </div>              
    </>    
  }

  getCurveEdit = () => {
    if(this.state.selectedCurveEdit != ""){
      return <>
        <div>
            <h3>Curves Configuration</h3>
            {/* <p>
              <span>Fill Color</span>
              <span className="button">
                <div className="roundedInput">
                  <input type="color" value={this.state.wellTracksColorTemporary[this.state.selectedCurveEdit]?.fill} onChange={(e)=>{this.setTrackTemporaryColor(this.state.selectedCurveEdit,"fill",e.target.value);}}/>
                </div>
              </span>
            </p> */}
            <p>
              <span>Line Color</span>
              <span className="button">
                <div className="roundedInput">
                  <input type="color" value={this.state.wellTracksColorTemporary[this.state.selectedCurveEdit]?.line} onChange={(e)=>{this.setTrackTemporaryColor(this.state.selectedCurveEdit,"line",e.target.value); }}/>
                </div>
              </span>
            </p> 
          </div>
      </>
    }

    return <></>
  }

  getWellDepthView = () => {
    return <>
      <select name="wellDepth" onChange={(event)=>{this.setWellDepthType(event)}} value={this.state.wellViewState.wellDepth}>
        {well_depth.map((value:any,idx:any)=>{
          var checked = false
          if(value.key == this.state.wellViewState.correlationDepth){
            checked = true
          }
          if(value.key != "RGT"){
            return <option key={idx} value={value.key} defaultChecked={checked}>{value.show}</option>
          }          
        })}
      </select>
    </>
  }

  getCorrelationDepthView = () => {
    return <>
      <select name="correlationDepth" onChange={(event)=>{this.setCorrelationDepthType(event)}} value={this.state.wellViewState.correlationDepth}>
        {well_depth.map((value:any,idx:any)=>{
          var checked = false
          if(value.key == this.state.wellViewState.correlationDepth){
            checked = true
          }
          return <option key={idx} value={value.key} defaultChecked={checked}>{value.show}</option>
        })}
      </select>
    </>
  }

  getTree = () =>{
    var trackWidth = this.state.wellViewState.trackWidth

    return <>
      <div className='sidebar-section' style={{ 'paddingBottom': '5px'}}>
          <details>
            <summary className='title'>Tracks <i className="arrow down"></i></summary>            
            <div>
              <h3>Tracks Configuration</h3>
              <p>
                <span>Tracks Font Type</span>
                <span className="button">
                  <div className="roundedInput">
                    {this.getFontsTypeView()}
                  </div>
                </span>
              </p>
              <p style={{display: "none"}}>
                <span>Tracks Font Size</span>
                <span className="button">
                    <div className="roundedInput">                      
                      <input type="number" 
                        name="tracksFontSize" 
                        id="tracksFontSize"
                        min={10} 
                        max={20}                         
                        value={this.state.wellViewState.trackFontSize} 
                        onChange={(event)=>{this.setTracksfontsSize(event)}} 
                        onKeyUp={(event)=>{this.setTracksfontsSize(event)}}
                      />
                    </div>
                </span>
              </p>
              <p style={{display: "none"}}>
                <span>Tracks Width</span>
                <span className="button">
                  <div className="roundedInput">
                    <input type="number" 
                      min={50} 
                      value={trackWidth} 
                      onChange={(event)=>{this.setTrackWidth(event)}} 
                      onKeyUp={(event)=>{this.setTrackWidth(event)}}
                    />
                  </div>
                </span>
              </p>
              <p>
                <span>Tracks Height</span>
                <span className="button">
                  <div className="roundedInput">                    
                    <input type="number" 
                      name="tracksHeight" 
                      id="tracksHeight"
                      min={1} 
                      max={1000} 
                      value={this.state.wellViewState.trackHeight} 
                      onChange={(event)=>{this.setTracksHeight(event)}} 
                      onKeyUp={(event)=>{this.setTracksHeight(event)}}
                    />
                  </div>
                </span>
              </p>
              <p>
                <span>Tracks Line Thickness</span>
                <span className="button">
                  <div className="roundedInput">
                    <input type="number" 
                      min={1}
                      max={100} 
                      value={this.state.wellViewState.trackLinesThickness} 
                      onChange={(event)=>{this.setTrackLinesThickness(event)}} 
                      onKeyUp={(event)=>{this.setTrackLinesThickness(event)}}
                    />
                  </div>
                </span>
              </p>
              {/* <p>
                <span>Tracks GAP</span>
                <span className="button">
                  <div className="roundedInput">
                    <input type="number" 
                      min={1} 
                      value={this.state.wellViewState.trackgap} 
                      onChange={(event)=>{this.setTrackGap(event)}} 
                      onKeyUp={(event)=>{this.setTrackGap(event)}}
                    />      
                  </div>
                </span>
              </p>
              <p>
                <span>Tracks Borders Color</span>
                <span className="button">
                  <div className="roundedInput">
                    <input type="color" 
                      style={{display:"block"}} 
                      value={this.state.wellViewState.trackborderColor} 
                      onChange={(event)=>{this.setTracksBorderColor(event)}}
                    />      
                  </div>
                </span>
              </p>
              <p>
                <span>Tracks Headers Borders Color</span>
                <span className="button">
                  <div className="roundedInput">
                    <input type="color" 
                      value={this.state.wellViewState.trackheadersborderColor} 
                      onChange={(event)=>{this.setTracksHeaderBorderColor(event)}}
                    />
                  </div>
                </span>
              </p> */}
            </div>
          </details>
      </div>
      <div className='sidebar-section' style={{ 'paddingBottom': '5px'}}>
        <details>
          <summary className='title'>Curves <i className="arrow down"></i></summary>
          {this.getCurvesList()}            
          {this.getCurveEdit()}
        </details>
      </div>
      <div className='sidebar-section' style={{ 'paddingBottom': '5px'}}>
        <details>
          <summary className='title'>Vertical Scale <i className="arrow down"></i></summary>
            <div> 
              <h3>Vertical Scale Configuration</h3>
              {
                this.state.viewName == ViewTypeTabName.WELL_VIEW && (
                  <p>
                    <span>Well depth unit</span>
                    <span className="button">
                      <div className="roundedInput">
                        {this.getWellDepthView()}
                      </div>
                    </span>
                  </p>
                )
              }
              { this.state.viewName == ViewTypeTabName.CORRELATION_VIEW && (
                <>
                  <p>
                    <span>Correlation depth unit</span>
                    <span className="button">
                      <div className="roundedInput">
                        {this.getCorrelationDepthView()}
                      </div>
                    </span>
                  </p>
                </>
              ) }
              
             
              
              
            {/* <p>
              <span>Grid Resolution</span>
              <span className="button">
              <div className="roundedInput">
                <input type="number" name="gridResolution" id="gridResolution" />
              </div>
              </span>
            </p>
            <p>
              <span>Scale Color</span>
              <span className="button">
                <div className="roundedInput">
                  <button></button>
                </div>
              </span>
            </p>
            <p>
              <span>Scale Font</span>
              <span className="button">
                <div className="roundedInput">
                  <select name="scaleFont" id="scaleFont"> 
                    <option value=""></option>
                  </select>
                </div>
              </span>
            </p>
            <p>
              <span>Show/Hide Zoom Tool</span>
              <span className="button">
                <div className="onoffswitch">                  
                  <input type="checkbox" 
                    name="showTracksZoom" 
                    className="onoffswitch-checkbox" 
                    id="showTracksZoom" 
                    checked={this.state.wellViewState.trackShowZoomBar} 
                    onChange={(event)=>{this.setShowTracksZoom(event)}} 
                  />
                  <label className="onoffswitch-label" htmlFor="showTracksZoom"></label>
                </div>
              </span>
            </p>
            <p>
              <span>Show/Hide Vertical Scale</span>
              <span className="button">
                <div className="onoffswitch">                      
                  <input type="checkbox" 
                    name="verticalScaleShow"  
                    id="verticalScaleShow"
                    className="onoffswitch-checkbox" 
                    checked={this.state.wellViewState.trackShowVerticalScale} 
                    onChange={(event)=>{this.setShowVerticalScale(event)}}
                  />
                  <label className="onoffswitch-label" htmlFor="verticalScaleShow"></label>
                </div>
              </span>
            </p> 
            <p className='margin-bottom-0'>
              <span>Single vertical scale parameterization</span>
              <span className="button">
                <div className="onoffswitch">
                  <input type="checkbox" 
                    name="verticalScaleParameterization" 
                    className="onoffswitch-checkbox" 
                    id="verticalScaleParameterization"
                  />
                  <label className="onoffswitch-label" htmlFor="verticalScaleParameterization"></label>
                </div>
              </span>
            </p>
            <p className="displayFlex margin-bottom-0">
              <span>Start</span>
              <span className="button">
                <div className="roundedInput">
                  <select name="verticalScaleStart" id="verticalScaleStart"> 
                    <option value=""></option>
                  </select>
                </div>
              </span>
            </p>
            <p className="displayFlex  margin-bottom-0">
              <span>Stop</span>
              <span className="button">
                <div className="roundedInput">
                  <select name="verticalScaleStart" id="verticalScaleStart"> 
                    <option value=""></option>
                  </select>
                </div>
              </span>
            </p>
            <p className="displayFlex">
              <span>Step</span>
              <span className="button">
                <div className="roundedInput">
                  <select name="verticalScaleStart" id="verticalScaleStart"> 
                    <option value=""></option>
                  </select>
                </div>
              </span>
            </p> */}
          </div>
        </details>
      </div>
      {/* <div className='sidebar-section' style={{ 'paddingBottom': '5px'}}>
        <details>
          <summary className='title'>Cursors <i className="arrow down"></i></summary>
          <div>
            <h3>Cursors Configuration</h3>
            <p>
              <span>Show/hide cursor</span>
              <span className="button">
                <div className="onoffswitch">
                  <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitch" checked/>
                  <label className="onoffswitch-label" htmlFor="myonoffswitch"></label>
                </div>
              </span>
            </p>
            <p>
              <span>Share cursor position for all wells</span>
              <span className="button">
                <div className="onoffswitch">
                  <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitch" checked/>
                  <label className="onoffswitch-label" htmlFor="myonoffswitch"></label>
                </div>
              </span>
            </p>
            <p>
              <span>Show cursor value</span>
              <span className="button">
                <div className="roundedInput">
                  <input type="number" name="showcursorValue" id="showcursorValue" />
                </div>
              </span>
            </p>
            <p>
              <span>Configure cursor style</span>
              <span className="button">
                <div className="roundedInput">
                  <select name="configurecursorStyle" id="configurecursorStyle"> 
                    <option value=""></option>
                  </select>
                </div>
              </span>
            </p>
          </div>
        </details>
      </div> */}
      {/* <div className='sidebar-section' style={{ 'paddingBottom': '5px'}}>
          <details>
              <summary className='title'>Markers <i className="arrow down"></i></summary>
              <div> 
                <h3>Markers Configuration</h3>
                <p>
                  <span>Show/hide marker position</span>
                  <span className="button">
                    <div className="onoffswitch">
                      <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitch" checked/>
                      <label className="onoffswitch-label" htmlFor="myonoffswitch"></label>
                    </div>
                  </span>
                </p>
                <p>
                  <span>Show/hide marker name</span>
                  <span className="button">
                    <div className="onoffswitch">
                      <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitch" checked/>
                      <label className="onoffswitch-label" htmlFor="myonoffswitch"></label>
                    </div>
                  </span>
                </p>
                <p>
                  <span>Connect markers between wells</span>
                  <span className="button">
                    <div className="onoffswitch">
                      <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitch" />
                      <label className="onoffswitch-label" htmlFor="myonoffswitch"></label>
                    </div>
                  </span>
                </p>
                <p>
                  <span>Marker Font</span>
                  <span className="button">
                    <div className="roundedInput">
                      <select name="markerFont" id="markerFont"> 
                        <option value=""></option>
                      </select>
                    </div>
                  </span>
                </p>
                <p>
                  <span>Marker Thickness</span>
                  <span className="button">
                    <div className="roundedInput">
                      <input type="number" name="markerThickness" id="markerThickness" />
                    </div>
                  </span>
                </p>
                <p>
                  <span>Marker Color</span>
                  <span className="button">
                    <div className="roundedInput">
                      <button></button>
                    </div>
                  </span>
                </p>
              </div>
          </details>
      </div>                */}
    </>
  }


  render() {
    var containerFluidStyle = {
      width: '100%',
      paddingLeft: '0',
      paddingRight: '0',
    };

    return (
      <>
      <ReactModal isOpen={this.state.showModal} style={{content:{left:"35%", right:"35%"}}} >
          <div style={{overflowY:"auto",overflowX:"hidden", maxHeight:"80vh"}}>
              <div className='row'>
                  <div className='col-4'>Curve Name</div>
                  <div className='col-4'>Track's Line Color</div>
                  <div className='col-4'>Track's Fill Color</div>
              </div>
              {
                this.state.logNames.map((logName,idx)=>{
                    return (
                    <div className='row'>
                        <div className='col-4'>{logName}</div>
                        <div className='col-4'><input type="color" value={this.state.wellTracksColorTemporary[logName]?.line} onChange={(e)=>{this.setTrackTemporaryColor(logName,"line",e.target.value)}}/></div>
                        <div className='col-4'><input type="color" value={this.state.wellTracksColorTemporary[logName]?.fill} onChange={(e)=>{this.setTrackTemporaryColor(logName,"fill",e.target.value)}}/></div>
                    </div>
                    )
                })
              }
          </div>
            <button onClick={(e)=>{
              this.setState({showModal:false});
              this.render();
              this.setColors(true);
            }}>Save</button>
            <button onClick={(e)=>{
              this.setState({showModal:false});
              this.render();
              this.setColors(false);
            }}>Close</button>
      </ReactModal>
        <div className="container-fluid" style={containerFluidStyle}>
          <div className="row">
            <div className="col-xs-12">
              {this.getTree()}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SettingsTabComponent;
